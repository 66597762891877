import React, { useState, useEffect } from 'react'
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';


const CopyButton = ({ text, delay, icon: Icon = CopyOutlined, style }) => {
    const [copied, setCopied] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(text)
        message.success('コピーしました。')
        setCopied(true)
    }

    useEffect(() => {
        if (!delay) return
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false)
            }, delay)
            return () => clearTimeout(timer)
        }
    }, [copied, delay])


    return (
        <Tooltip style={style} title='コピー'>
            <Icon onClick={copy} className='copy-icon' style={{ color: copied && '#1890ff'}} />
        </Tooltip>
    )
}

export default CopyButton