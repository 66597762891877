import React, { useState } from 'react';
import { Table, Input, Modal, Descriptions, Typography, Button } from 'antd';
import algoliasearch from 'algoliasearch/lite';
import './ProductSearch.less';
import {
    InstantSearch,
    connectSearchBox,
    Highlight,
    connectInfiniteHits
} from 'react-instantsearch-dom';
import { SearchOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
);

// declared outside of the main component to prevent resetting upon rerender
const CustomSearchBox = connectSearchBox(({ refine }) => {
    return (
        <Input
            prefix={<SearchOutlined style={{ color: 'rgb(118, 118, 118)' }} />}
            className="detail-search-box"
            placeholder="商品内容を検索できます。"
            size="large"
            allowClear
            onChange={(event) => {
                const searchString = event.target.value;
                refine(searchString);
            }}
        />
    );
});

const conditionalHighlight = (hit, attribute) => {
    if (hit._highlightResult?.[attribute]) {
        return <Highlight attribute={attribute} hit={hit}/>;
    }

    return hit[attribute];
};

const ProductSearch = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({}); //this set to the pdf record to be viewed when a row is clicked

    const openModal = (record) => {
        setCurrentRecord(record);
        setModalVisible(true);
    };

    const closeModal = () => {
        setCurrentRecord({});
        setModalVisible(false);
    };

    const columnRendererFor = (attribute) => (text, record) => {
        return (
            <a
                href="#0"
                onClick={e => {
                    openModal(record);
                    e.preventDefault();
                }}
            >
                {conditionalHighlight(record, attribute)}
            </a>
        );
    };

    const columns = [
        //ant table columns are defined here
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: 'code',
            render: columnRendererFor('code')
        },
        {
            title: <Typography.Text strong>商品名1</Typography.Text>,
            dataIndex: 'name1',
            render: columnRendererFor('name1')
        },
        {
            title: <Typography.Text strong>商品名2</Typography.Text>,
            dataIndex: 'name2',
            render: columnRendererFor('name2')
        }
    ];

    const InfiniteResults = ({ hits, hasMore, refineNext }) => {
        if (hits.length > 0) {
            return (
                <>
                    <Table
                        fixed={'top'}
                        scroll={{ y: 700 }}
                        pagination={false}
                        className="detail-search-table"
                        columns={columns}
                        dataSource={hits}
                    />
                    <Button disabled={hasMore ? false : true} style={{ marginTop: '1rem' }} onClick={refineNext}>もっと見る</Button>
                </>
            );
        }

        return <div/>;
    };

    const CustomInfiniteResults = connectInfiniteHits(InfiniteResults);

    return (
        <div className="detail-search">
            <InstantSearch //main instantSearch component
                searchClient={searchClient}
                indexName={'products_' + process.env.REACT_APP_ENVIRONMENT}
            >
                <CustomSearchBox />
                <CustomInfiniteResults/>

                <Modal
                    title={<Typography.Text strong>データ詳細</Typography.Text>}
                    visible={modalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    width={1200}
                >
                    <Descriptions column={2} labelStyle={{ width: '10vw' }} bordered>
                        <Descriptions.Item label="品番" span={2}>
                            {conditionalHighlight(currentRecord, 'code')}
                        </Descriptions.Item>
                        <Descriptions.Item label="商品名１" span={1}>
                            {conditionalHighlight(currentRecord, 'name1')}
                        </Descriptions.Item>
                        <Descriptions.Item label="商品名２" span={1}>
                            {conditionalHighlight(currentRecord, 'name2')}
                        </Descriptions.Item>

                    </Descriptions>
                </Modal>
            </InstantSearch>
        </div>
    );
};

ProductSearch.propTypes = {
    hits: propTypes.array,
    hasMore: propTypes.bool,
    refineNext: propTypes.func
};

export default ProductSearch;
