import React, { useState } from 'react';
import {
    Row,
    Typography,
    Result,
    Button,
    Empty,
    Spin
} from 'antd';
import { CloseCircleTwoTone, SyncOutlined } from '@ant-design/icons';
import { GET_PRESIGNED_UPLOAD_URL, GET_IMPORT_JOB } from '../../queries';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery, useMutation } from '@apollo/client';
import '../UploadArea/uploadarea.less';
import { CREATE_IMPORT_JOB } from '../../mutations';

const Import = () => {
    const [files, setFiles] = useState();
    const [failedFiles, setFailedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentObjectKey, setCurrentObjectKey] = useState(null);
    const [currentStatus, setCurrentStatus] = useState('beforeUpload');
    const [currentImportJob, setCurrentImportJob] = useState(null);
    const { Text } = Typography;

    const objectKeyForFile = (file) => {
        return 'xlsx/' + new Date().toISOString() + file.name;
    };

    const [getImportJobData] = useLazyQuery(GET_IMPORT_JOB, {
        onCompleted: (data) => {
            setCurrentImportJob(data.importJob);
        },
        fetchPolicy: 'network-only' // Doesn't check cache before making a network request
    });

    /* eslint-disable no-use-before-define */
    const [getPresignedUploadUrl, { loading, data }] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        onCompleted: (data) => {
            // Pop 1st file from the stack of files
            const file = files.shift();
            setCurrentFile(file.name);
            setFiles(files);
            uploadToS3(
                file,
                data.presignedUploadUrl.uploadUrl,
                // error callback
                (response) => {
                    console.log('ERROR!' + response.code);
                    setFailedFiles([...failedFiles, currentFile]);
                },
                // success callback
                () => {
                    // trigger the job, this creates a row in the import_jobs table,
                    // and rails enqueues a job to process the file
                    createImportJob({ variables: { input: { s3ObjectKey: currentObjectKey } } });
                    setCurrentStatus('successUpload');
                }
            );
        }
    });

    const [createImportJob] = useMutation(CREATE_IMPORT_JOB, {
        onError: (error) => {
            console.log(error);
            setFailedFiles([...failedFiles, currentFile]);
            if (files.length >= 1) {
                const objectKey = objectKeyForFile(files[0]);
                setCurrentObjectKey(objectKey);
                setCurrentStatus('loadingUpload');
                getPresignedUploadUrl({ variables: { objectKey } });
            } else {
                setCurrentStatus('failedUpload');
            }
        },
        onCompleted: (data) => {
            setCurrentImportJob(data.createImportJob.importJob);
            if (files.length >= 1) {
                // Upon completion - recurse until the stack is empty
                const objectKey = objectKeyForFile(files[0]);
                setCurrentObjectKey(objectKey);
                setCurrentStatus('loadingUpload');
                getPresignedUploadUrl({ variables: { objectKey } });
            } else {
                setCurrentStatus('successUpload');
            }
        }
    });
    /* eslint-enable no-use-before-define */

    const description = <React.Fragment>
        インポートしたい得意先マスタ・商品マスタデータここにドラッグ＆ドロップ
        <br/>
        または
    </React.Fragment>;

    const instruction = (
        <React.Fragment>
            <Text strong style={{ color: 'black' }}>
                データをインポートする前に以下のことを確認してください。
                <ul>
                    <li>CSV形式で保存して、「.csv」のファイルをアップロードしてください。</li>
                    <li>セル内に改行を入れないようにしてください。特に一行目に改行があると誤作動を起こします。</li>
                    <li>商品マスタは以下の項目が入力されていることを確認してください。</li>
                    <ul style={{ paddingLeft: '2rem', marginBottom: '0' }}>
                        <li> 商品コード、商品名１、商品名２, 単位名, T価格, O価格, F価格, G価格,原価, 定価, 単位改定日 </li>
                    </ul>
                    <li>得意先マスタは以下の項目が入力されていることを確認してください:</li>
                    <ul style={{ paddingLeft: '2rem' }}>
                        <li>
                            得意先名称カナ、得意先名、得意先コード、得意先住所、得意先住所、得意先電話番号、得意先ファックス番号、得意先ファックス番号, 現場名称, 現場コード
                        </li>
                    </ul>
                </ul>
            </Text>
        </React.Fragment>
    );

    const { getRootProps, getInputProps } = useS3Upload({
        presignedUploadUrl: data && data.presignedUploadUrl.uploadUrl,
        onUploadStart: acceptedFiles => {
            // Preload all files here in a stack 'files'
            setFiles(acceptedFiles);
            const objectKey = objectKeyForFile(acceptedFiles[0]);
            setCurrentObjectKey(objectKey);
            setCurrentStatus('loadingUpload');
            getPresignedUploadUrl({ variables: { objectKey } });
        }
    });

    const handleConfirmJobStatusButtonClick = () => {
        getImportJobData({ variables: { importJobId: parseInt(currentImportJob.id) } });
    };

    if (loading) { return <SyncOutlined spin />; }

    return (
        <>
            {currentStatus === 'beforeUpload' && (
                <>
                    <Row justify="center">
                        <Empty
                            description={description}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            id="upload-zone"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <Row justify="center">
                                <Button type="primary">ファイルを選択</Button>
                            </Row>
                        </Empty>
                    </Row>
                    {instruction}
                </>
            )}
            {currentStatus === 'loadingUpload' && (
                <>
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                    <br />
                    <Row justify="center">アップロード中</Row>
                    <Row justify="center">{currentFile}</Row>
                </>
            )}
            {currentStatus === 'failedUpload' && (
                <>
                    <Row justify="center">
                        <CloseCircleTwoTone style={{ fontSize: '72px' }} twoToneColor="#F5222D" />
                    </Row>
                    <br />
                    <Row justify="center">XLSXを分類することができませんでした。</Row>
                </>
            )}
            {currentStatus === 'successUpload' && (
                <>
                    <Row justify="center">
                        <Result
                            status="success"
                            title="アップロードが完了しました。データベースへの取り込みが完了するまで5分ほどお待ちください。"
                            subTitle={currentFile}
                            extra={[
                                <Button type="primary" key="console" onClick={() => setCurrentStatus('beforeUpload')}>
                                    OK
                                </Button>,
                                <Button type="secondary" key="console" onClick={handleConfirmJobStatusButtonClick}>
                                    ジョブを確認する
                                </Button>
                            ]}
                        />
                    </Row>
                    {currentImportJob && (
                        // TODO: this could be much nicer, like:
                        // - have a proper progress bar
                        // - don't make the user click a button to see the status (polling?)
                        // - show more useful information like number of duplicates or number of invalid records
                        // - show a link to an import jobs page, where the user can see all their import jobs
                        // - give the user an ability to retrigger the job if it looks stuck
                        <Row justify="center">
                            <ul>
                                <li>ジョブID: {currentImportJob.id}</li>
                                <li>ジョブの開始日時: {currentImportJob.createdAt}</li>
                                <li>ジョブの合計行数: {currentImportJob.numRows}</li>
                                <li>インポート済み行数: {currentImportJob.numImported}</li>
                                <li>ジョブの完了日時: {currentImportJob.finishedAt}</li>
                            </ul>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default Import;
