import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import { DESTROY_FAXES } from '../../mutations';
import propTypes from 'prop-types';

const DeleteOrdersButton = ({ selectedOrderIds, handleCompleted }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [destroyFaxes] = useMutation(DESTROY_FAXES, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        destroyFaxes({ variables: { input: { ids: selectedOrderIds } } })
        .then(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.success('選択したXDWを削除しました。');
        })
        .catch(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.error('選択したXDWを削除できませんでした。');
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Popconfirm
            title='選択したXDWを削除してよろしいですか。'
            cancelText='キャンセル'
            okText='削除する'
            visible={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
            placement='topRight'
        >
            <Button
                onClick={showPopconfirm}
                icon={<DeleteOutlined/>}>
                削除
            </Button>
        </Popconfirm>
    );
};

DeleteOrdersButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedOrderIds: propTypes.array
};

export default DeleteOrdersButton;
