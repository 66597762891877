import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Descriptions, Modal } from 'antd';

const NewUserModal = ({ userInfo }) => {
    const [visible, setVisible] = useState(true);

    return (
        <Modal
            title="新規ユーザー"
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Descriptions layout='vertical'>
                <Descriptions.Item label='ユーザーID' span={3}>{userInfo.username}</Descriptions.Item>
                <Descriptions.Item label='新しいパスワード' span={3}>{userInfo.password}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

NewUserModal.propTypes = {
    userInfo: propTypes.object
};

export default NewUserModal;
