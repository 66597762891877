import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Dropdown } from 'antd';
import { PATHS } from '../../constants';
import propTypes from 'prop-types';

const UpdateOrderDropdown = ({ order }) => {
    if (!order.orderType) {
        return <></>;
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Link
                    to={{
                        pathname: PATHS.addCustomer,
                        state: { order }
                    }}
                >
                    フォーマットに新規顧客を追加する
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link
                    to={{
                        pathname: PATHS.addBoundingBox,
                        state: { order }
                    }}
                >
                    フォーマットに枠を追加する
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link
                    to={{
                        pathname: PATHS.formatMatch,
                        state: { order }
                    }}
                >
                    フォーマットがFAXと合致しない
                </Link>
            </Menu.Item>
        </Menu>
    );

    return <Dropdown overlay={menu} placement="bottomRight">
        <Button>修正する</Button>
    </Dropdown>;
};

UpdateOrderDropdown.propTypes = {
    order: propTypes.object.isRequired
};

export default UpdateOrderDropdown;
