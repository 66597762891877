import React from 'react';
import './App.less';
import {
    LoginForm,
    AddNewClient,
    Dashboard,
    OrdersTable,
    PrivateRoute,
    UploadArea,
    UserSettings,
    NewFormat,
    AddBoundingBox,
    FixBoundingBox,
    Import,
    FormatMatch,
    DetailSearch
} from './Components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { authenticationService } from './Utilities/authenticationService';
import history from './Utilities/history';
import { KINTARO_API_ROOT, PATHS } from './constants';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import ProductSearch from './Components/ProductSearch/ProductSearch';

class App extends React.Component {
    state = {
        currentUser: null
    };

    componentDidMount = () => {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    handleLogout = () => {
        authenticationService.logout();
        history.push('/login');
    }

    render = () => {
        const { currentUser } = this.state;
        const httpLink = new HttpLink({ uri: KINTARO_API_ROOT + '/graphql' });
        const errorLink = onError(({ networkError, graphQLErrors }) => {
            if (networkError) {
                console.log('ERRORLINK', networkError);
                if (networkError.statusCode === 401) {
                    console.log('401');
                    this.handleLogout();
                }
            }

            if (graphQLErrors) {
                console.log('ERRORLINK', graphQLErrors);
            }
        });
        const authLink = setContext((_, { headers }) => {
            const token = localStorage.getItem('currentUser');
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${currentUser.token}` : ''
                }
            };
        });

        const client = new ApolloClient({
            link: ApolloLink.from([errorLink, authLink, httpLink]),
            cache: new InMemoryCache()
        });

        return (
            <ApolloProvider client={client}>
                <Router basename={ process.env.PUBLIC_URL }>
                    <Switch>
                        <PrivateRoute path={ PATHS.newFormat } component={NewFormat} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.upload } component={UploadArea} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.orders } component={OrdersTable} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.import } component={Import} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.addCustomer } component={AddNewClient} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.addBoundingBox } component={AddBoundingBox} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.fixBoundingBox } component={FixBoundingBox} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.formatMatch } component={FormatMatch} onLogout={this.handleLogout} />
                        {currentUser?.admin && (
                            <PrivateRoute path={ PATHS.dashboard } component={Dashboard} onLogout={this.handleLogout} />
                        )}
                        <PrivateRoute path={ PATHS.detailSearch } component={DetailSearch} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.productSearch } component={ProductSearch} onLogout={this.handleLogout} />
                        <PrivateRoute path={ PATHS.userSettings } component={UserSettings} onLogout={this.handleLogout} />
                        <Route path={ PATHS.login } component={LoginForm}/>
                        <Route render={() => <Redirect to={PATHS.upload} />} />
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
