import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Button, Pagination, Spin, Row, Space } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { BoundingBox } from '../';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../constants';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OrderPdf = ({
    pageWidth, boundingBoxEnabled, renderMode, file, unmodifiable,
    onChange: handleChange, getAspectRatio, annotationData,
    orderTypeDimensions, setNewOrderTypeWidth, setNewOrderTypeHeight,
    disablePanning, isOrderBoundingBoxes, initialPageNum, showHideButton,
    siblings
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(initialPageNum || 1);
    const [pdfObject, setPdfObject] = useState(null);
    const [pdfContents, setPdfContents] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [hideBoxes, setHideBoxes] = useState(false);
    const aspectRatio = width / height;
    getAspectRatio(aspectRatio);
    const history = useHistory();

    const loadPage = page => {
        pdfObject.getPage(page).then(page => {
            const viewport = page.getViewport({ scale: 1 });
            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');
            canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
            canvas.width = viewport.width || viewport.viewBox[2]; /* viewport.width is also NaN */
            setHeight(canvas.height);
            setWidth(canvas.width);
            setNewOrderTypeHeight(canvas.height);
            setNewOrderTypeWidth(canvas.width);
            page.render({ canvasContext, viewport }).promise.then(() => {
                setPdfContents(canvas.toDataURL('image/jpeg'));
            }).catch(err => console.log(err.message));
        });
    };

    if (pdfObject) { loadPage(pageNumber); }

    const onDocumentLoadSuccess = pdf => {
        setNumPages(pdf.numPages);
        setPdfObject(pdf);
        setPageNumber(pageNumber);
    };

    return (
        <TransformWrapper pan={{ disabled: disablePanning }}>
            {({ zoomIn, zoomOut }) => (
                <React.Fragment>
                    {pdfContents && (
                        <Row justify="center" style={{ margin: '20px' }}>
                            <Space>
                                <Button shape="round" icon={<ZoomInOutlined />} onClick={zoomIn} />
                                <Button shape="round" icon={<ZoomOutOutlined />} onClick={zoomOut} />
                                {showHideButton && (
                                    <Button
                                        shape="round"
                                        icon={hideBoxes ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                                        onClick={() => setHideBoxes(!hideBoxes)}
                                    />
                                )}
                            </Space>
                        </Row>
                    )}
                    <Row justify="center">
                        <TransformComponent>
                            <Document
                                renderMode={(!boundingBoxEnabled && renderMode) || 'none'}
                                file={file}
                                loading={<Spin />}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={console.error}
                                options={{
                                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                    cMapPacked: true
                                }}
                                error="PDFファイルの読み込みに失敗しました。"
                            >
                                <Page
                                    scale={1}
                                    width={pageWidth}
                                    renderAnnotationLayer={false}
                                    pageNumber={pageNumber}
                                />
                            </Document>
                            {boundingBoxEnabled && pdfContents && orderTypeDimensions && (
                                <BoundingBox
                                    onChange={handleChange}
                                    annotationData={hideBoxes ? [] : annotationData}
                                    unmodifiable={unmodifiable}
                                    pdfFile={pdfContents}
                                    orderTypeDimensions={orderTypeDimensions}
                                    originalPdfWidth={width}
                                    originalPdfHeight={height}
                                    width={pageWidth || width}
                                    height={pageWidth / aspectRatio || height}
                                    isOrderBoundingBoxes={isOrderBoundingBoxes}
                                />
                            )}
                        </TransformComponent>
                    </Row>
                    {pdfContents && (
                        <Row justify="center">
                            <Pagination
                                style={{ margin: '20px' }}
                                onChange={page => {
                                    if (siblings?.find(e => e.pageNum === page)) {
                                        const pageId = siblings.find(e => e.pageNum === page).id;
                                        history.push(PATHS.orderDetails.replace(':orderId', pageId));
                                    }

                                    setPageNumber(page);
                                }}
                                defaultPageSize={1}
                                current={pageNumber}
                                total={numPages}
                            />
                        </Row>
                    )}
                </React.Fragment>
            )}
        </TransformWrapper>
    );
};

OrderPdf.propTypes = {
    unmodifiable: propTypes.bool,
    renderMode: propTypes.string,
    file: propTypes.string.isRequired,
    annotationData: propTypes.array,
    pageWidth: propTypes.number,
    boundingBoxEnabled: propTypes.bool,
    onChange: propTypes.func,
    getAspectRatio: propTypes.func,
    orderTypeDimensions: propTypes.object,
    setNewOrderTypeWidth: propTypes.func,
    setNewOrderTypeHeight: propTypes.func,
    disablePanning: propTypes.bool,
    isOrderBoundingBoxes: propTypes.bool,
    initialPageNum: propTypes.number,
    showHideButton: propTypes.bool,
    siblings: propTypes.array
};

OrderPdf.defaultProps = {
    unmodifiable: false,
    renderMode: 'canvas',
    boundingBoxEnabled: false,
    disablePanning: false,
    getAspectRatio: () => {},
    setNewOrderTypeWidth: () => {},
    setNewOrderTypeHeight: () => {}
};

export default OrderPdf;
