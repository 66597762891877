import React from 'react';
import propTypes from 'prop-types';
import { Line } from '@ant-design/charts';

const LineChart = ({ data }) => {
    const config = {
        data,
        xField: 'timestamp',
        yField: 'ordersCount',
        label: {},
        point: {
            size: 2,
            shape: 'circle',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2
            }
        },
        tooltip: { showMarkers: false }
    };
    return <Line {...config} />;
};

LineChart.propTypes = {
    data: propTypes.array
};

export default LineChart;
