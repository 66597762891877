import gql from 'graphql-tag';

export const CREATE_USER = gql`
mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const CREATE_CUSTOMERS = gql`
mutation createCustomers($input: createCustomersInput!) {
    createCustomers(input: $input) {
        customers {
            id
            code
            kanaName
            nameOne
            nameTwo
        }
    }
}
`;

export const CREATE_PRODUCTS = gql`
mutation createProducts($input: createProductsInput!) {
    createProducts(input: $input) {
        products {
            id
            code
            name1
            name2
        }
    }
}
`;
export const DELETE_USERS = gql`
mutation deleteUser($input: deleteUserInput!) {
    deleteUser(input: $input) {
        users {
            id
            username
        }
    }
}
`;

export const PASSWORD_RESET = gql`
mutation passwordReset($input: passwordResetUserInput!) {
    passwordResetUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const CHANGE_PASSWORD = gql`
mutation changePassword($input: changePasswordUserInput!) {
    changePasswordUser(input: $input) {
        user {
            id
            username
            password
        }
    }
}
`;

export const DESTROY_ORDERS = gql`
mutation destroyOrder($input: destroyOrderInput!) {
    destroyOrder(input: $input) {
        orders {
            id
        }
    }
}
`;

export const UPDATE_ORDER = gql`
mutation updateOrder($input: updateOrderInput!) {
    updateOrder(input: $input) {
        order {
            id
        }
    }
}
`;

export const UPDATE_ORDERS_PRODUCT = gql`
mutation updateOrdersProduct($input: updateOrdersProductInput!) {
    updateOrdersProduct(input: $input) {
        ordersProduct {
            id
        }
    }
}
`;

export const ADD_PRODUCT_TO_ORDER = gql`
mutation addProductToOrder($input: addProductToOrderInput!) {
    addProductToOrder(input: $input) {
        ordersProduct {
            id
        }
    }
}
`;

export const REMOVE_PRODUCT_FROM_ORDER = gql`
mutation removeProductFromOrder($input: removeProductFromOrderInput!) {
    removeProductFromOrder(input: $input) {
        ordersProducts {
            id
        }
    }
}
`;

export const CREATE_NEW_FORMAT = gql`
mutation createOrderType($input: createOrderTypeInput!) {
    createOrderType(input: $input) {
        orderType {
            id
        }
    }
}
`;

export const UPDATE_EXISTING_FORMAT = gql`
mutation updateOrderType($input: updateOrderTypeInput!) {
    updateOrderType(input: $input) {
        orderType {
            id
        }
    }
}
`;

export const CREATE_BOUNDING_BOX = gql`
mutation createBoundingBox($input: createBoundingBoxInput!) {
    createBoundingBox(input: $input) {
        boundingBox {
            id
        }
    }
}
`;

export const CREATE_ORDER = gql`
mutation createOrder($input: createOrderInput!) {
    createOrder(input: $input) {
        order {
            id
            s3ObjectKey
            progress
            userId
        }
        errors {
            attribute
            message
        }
    }
}
`;

export const CREATE_IMPORT_JOB = gql`
mutation createImportJob($input: createImportJobInput!) {
    createImportJob(input: $input) {
        importJob {
            id
            s3ObjectKey
        }
    }
}
`;

export const DESTROY_FAXES = gql`
mutation destroyFaxes($input: destroyFaxesInput!) {
    destroyFaxes(input: $input) {
        faxes {
            id
        }
    }
}
`;