import React, { useState } from 'react';
import propTypes from 'prop-types';
import { OrderPdf, CustomersSearch } from '../';
import { UpdateOrderTypeButton } from '../';
import { Divider, Typography, Row, Col, Form } from 'antd';
import { PATHS, GREY } from '../../constants';
import { Redirect } from 'react-router-dom';
import { useWindowWidth } from '../../Utilities/useWindowWidth';

const AddNewClient = ({ location }) => {
    const [customerId, setCustomerId] = useState(null);
    const screenWidth = useWindowWidth();
    const [form] = Form.useForm();

    if (!location || !location.state || !location.state.order) {
        return <Redirect to={PATHS.orders}/>;
    }

    const order = location.state.order;
    const clusterName = (order.orderType && order.orderType.name) || 'N/A';
    let boundingBoxes = [];
    let isOrderBoundingBoxes = false;

    if (order.boundingBoxes?.length > 0) {
        isOrderBoundingBoxes = true;
        boundingBoxes = order.boundingBoxes;
    } else if (order.orderType?.boundingBoxes?.length > 0) {
        boundingBoxes = order.orderType.boundingBoxes;
    }

    return <>
        <Row>
            <Col span={6}>
                <Typography.Title level={4} strong>フォーマットに新規顧客を追加する</Typography.Title>
            </Col>
        </Row>
        <Divider/>
        <Row>
            <Col span={12}>
                <Form form={form}
                    labelCol={ { span: 24 } }
                    wrapperCol={ { span: 14 } }
                    labelAlign='left'
                    layout='vertical'
                    colon={false}
                    name="saveOrder"
                >
                    <Typography.Paragraph>
                        アップロードしたPDFの得意先情報を入力してください。
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        得意先が見つからない場合はマスタデータインポートで得意先マスタをインポートしてください。
                    </Typography.Paragraph>
                    <Form.Item name='customer'>
                        <CustomersSearch onSelect={setCustomerId} />
                    </Form.Item>
                    <Form.Item>
                        <UpdateOrderTypeButton
                            orderId={order.id}
                            customerId={customerId}
                            orderTypeId={order.orderType.id}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={12} style={{ backgroundColor: GREY, marginTop: '-24px' }}>
                { order.presignedDownloadUrl &&
                <>
                    <OrderPdf pageWidth={screenWidth * 0.5} file={order.presignedDownloadUrl}
                        unmodifiable boundingBoxEnabled={order.orderType &&
                            order.orderType.boundingBoxes &&
                            order.orderType.boundingBoxes.length > 0}
                        orderTypeDimensions={{ width: order.orderType.width, height: order.orderType.height }}
                        annotationData={boundingBoxes}
                        isOrderBoundingBoxes={isOrderBoundingBoxes}
                    />
                    { order.orderType && !order.orderType.unknown &&
                    <>
                        <br/>
                        <Typography.Text className='ant-row ant-row-center' strong>
                            フォーマットID : { clusterName }
                        </Typography.Text>
                        <br/>
                        <OrderPdf
                            pageWidth={screenWidth * 0.35}
                            file={order.orderType.samplePresignedDownloadUrl}
                        />
                    </>
                    }
                </>
                }
            </Col>
        </Row>
    </>;
};

AddNewClient.propTypes = {
    location: propTypes.object.isRequired
};

export default AddNewClient;
