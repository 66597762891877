import React, { useState } from 'react';
import propTypes from 'prop-types';
import { OrderPdf } from '../';
import SubmitNewFormatButton from '../NewFormat/SubmitNewFormatButton';
import { Divider, Typography, Row, Col, Space } from 'antd';
import { PATHS, GREY } from '../../constants';
import { Redirect } from 'react-router-dom';
import { useWindowWidth } from '../../Utilities/useWindowWidth';

const FixBoundingBox = ({ location }) => {
    const screenWidth = useWindowWidth();
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(0);
    const [boundingBoxes, setBoundingBoxes] = useState((
        location && location.state && location.state.order && location.state.order.orderType &&
            location.state.order.orderType.boundingBoxes) || []);

    if (!location || !location.state || !location.state.order) {
        return <Redirect to={PATHS.orders}/>;
    }

    const order = location.state.order;
    const clusterName = (order.orderType && order.orderType.name) || 'N/A';

    return <>
        <Row>
            <Col span={6}>
                <Typography.Title level={4} strong>フォーマット枠の位置を訂正する</Typography.Title>
            </Col>
        </Row>
        <Divider/>
        <Row>
            <Col span={8}>
                <Space direction='vertical'>
                    フォーマットの枠の位置を訂正してください。
                    <Typography.Paragraph>
                        <ul>
                            <li>
                                手順 1: 納品先、得意先、商品内容を赤枠で囲んでください。
                            </li>
                            <li>
                                手順 2: 囲んだらドロップダウンメニューを使って該当するものを選択してください。
                                        ドロップダウンメニューが青色に変更してから完了を押してください。
                            </li>
                        </ul>
                    </Typography.Paragraph>
                    <SubmitNewFormatButton boundingBoxes={boundingBoxes}
                        unknownFormat={false}
                        aspectRatio={aspectRatio}
                        newOrderTypeWidth={width}
                        newOrderTypeHeight={height}
                        orderTypeId={order.orderType && order.orderType.id}
                        orderId={order.id} />
                </Space>
            </Col>
            <Col span={16} style={{ backgroundColor: GREY, marginTop: '-24px' }}>
                { order.presignedDownloadUrl &&
                <>
                    <OrderPdf
                        file={order.presignedDownloadUrl}
                        unmodifiable={false}
                        onChange={setBoundingBoxes}
                        pageWidth={screenWidth * 0.5}
                        setNewOrderTypeWidth={width => setWidth(width)}
                        setNewOrderTypeHeight={height => setHeight(height)}
                        annotationData={boundingBoxes}
                        getAspectRatio={(aspectRatio) => setAspectRatio(aspectRatio)}
                        boundingBoxEnabled
                        orderTypeDimensions={order.orderType && { width: order.orderType.width, height: order.orderType.height }}
                        renderMode={'none'}
                        disablePanning={true}
                    />

                    { order.orderType && !order.orderType.unknown &&
                    <>
                        <br/>
                        <Typography.Text className='ant-row ant-row-center' strong>
                            フォーマットID : { clusterName }
                        </Typography.Text>
                        <br/>
                        <OrderPdf
                            pageWidth={screenWidth * 0.35}
                            file={order.orderType.samplePresignedDownloadUrl}
                        />
                    </>
                    }
                </>
                }
            </Col>
        </Row>
    </>;
};

FixBoundingBox.propTypes = {
    location: propTypes.object.isRequired
};

export default FixBoundingBox;
