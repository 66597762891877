import React from 'react';
import { Layout, Tabs } from 'antd';
import { SettingOutlined, TeamOutlined } from '@ant-design/icons';
import AccountInformation from './AccountInformation';
import UserManagement from './UserManagement';

const { Content } = Layout;

const UserSettings = () => (
    <Layout>
        <Content
            className="site-layout-background"
            style={{
                padding: '0px 0px 0px 0px'
            }}
        >
            <Tabs defaultaActiveKey='profile' className="site-layout-background">
                <Tabs.TabPane tab='アカウント情報' key='accountInformation' icon={<SettingOutlined />}>
                    <AccountInformation/>
                </Tabs.TabPane>
                { JSON.parse(localStorage.currentUser).admin &&
                    <Tabs.TabPane tab='ユーザー管理' key='UserManagement' icon={<TeamOutlined />}><UserManagement/></Tabs.TabPane>
                }
            </Tabs>
        </Content>
    </Layout>
);

export default UserSettings;
