import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { UPDATE_ORDER } from '../../mutations';
import propTypes from 'prop-types';

const UpdateOrderButton = ({ id, handleCompleted, text, attribute, value, disabled }) => {
    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    let gqlVariables = { variables: { input: { id } } };
    gqlVariables.variables.input[attribute] = value;

    return <Button disabled={disabled} onClick={() => {
        updateOrder(gqlVariables);
    }}>
        { text }
    </Button>;
};

UpdateOrderButton.propTypes = {
    handleCompleted: propTypes.func,
    text: propTypes.string,
    attribute: propTypes.string,
    value: propTypes.any,
    disabled: propTypes.bool,
    id: propTypes.number
};

UpdateOrderButton.defaultProps = {
    disabled: false
};

export default UpdateOrderButton;
