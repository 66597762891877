import React, { useState, useEffect, useMemo } from 'react';
import {
    message,
    Tabs,
    Space,
    Select,
    Spin,
    Divider,
    Typography,
    Alert,
    Form,
    Input,
    Button,
    Row,
    Col,
    Tooltip,
    Table
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams, Redirect, withRouter, useHistory } from 'react-router-dom';
import { OrderPdf } from '../';
import { GET_ORDER, GET_PRODUCTS, GET_CUSTOMER_PRODUCTS, GET_CUSTOMER_SITES, GET_CUSTOMERS, GET_CUSTOMER, GET_ORDERS_PRODUCTS, GET_PRODUCT_RECOMMENDATIONS } from '../../queries';
import UpdateOrderButton from './UpdateOrderButton.js';
import UpdateOrderDropdown from './UpdateOrderDropdown.js';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { ADD_PRODUCT_TO_ORDER, REMOVE_PRODUCT_FROM_ORDER, UPDATE_ORDER, UPDATE_ORDERS_PRODUCT, UPDATE_EXISTING_FORMAT } from '../../mutations';
import { PATHS, GREY } from '../../constants';
import propTypes from 'prop-types';
import { useWindowWidth } from '../../Utilities/useWindowWidth';
import debounce from 'lodash/debounce';
import './orderDetails.less';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import CopyButton from '../../Utilities/CopyButton';

const { TextArea } = Input;

const OrderDetails = ({ location, onAction }) => {
    const csvLink = React.createRef();
    const { orderId } = useParams();
    const [form] = Form.useForm();
    const [orderUpdated, setOrderUpdated] = useState(false);
    const history = useHistory();
    const [customer, setCustomer] = useState({});
    const [site, setSite] = useState({});
    const [products, setProducts] = useState([]);
    const [boundingBoxes, setBoundingBoxes] = useState([]);
    const [addingItem, setAddingItem] = useState(false);
    const [messagePresent, setMessagePresent] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [isOrderBoundingBoxes, setIsOrderBoundingBoxes] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);
    const [customerSites, setCustomerSites] = useState([]);
    const [searching, setSearching] = useState(false);
    const [memoText, setMemoText] = useState('');
    const [editingMemo, setEditingMemo] = useState(false);
    const [numSlots, setNumSlots] = useState(null);
    const [candidates, setCandidates] = useState([]);

    useQuery(GET_CUSTOMERS, {
        onCompleted: (data) => {
            setAllCustomers(data.customers.edges);
            console.log(data)
        }
    }); 

    const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
        onCompleted: (data) => {
            setCustomer(data.customer);
            updateOrder({ variables: { input: { id: orderId, customerId: parseInt(data.customer.id) } } })
        }
    })

    const [updateExistingFormat] = useMutation(UPDATE_EXISTING_FORMAT);

    const { data, loading, error, refetch } = useQuery(GET_ORDER, {
        fetchPolicy: 'no-cache',
        variables: { orderId: parseInt(orderId) },
        onError: (error) => {
            console.log('GQL ERROR', error);
        }
    });
    const [getProducts, { data: dataProducts, loading: loadingProducts }] = useLazyQuery(GET_PRODUCTS, {
        onCompleted: (data) => {
            setProductOptions(data.products.edges.map(product => ({
                value: product.node.id,
                label: `${product.node.name1} - ${product.node.name2}`
            })));
        }
    });

    const [getOrdersProducts] = useLazyQuery(GET_ORDERS_PRODUCTS, {
        fetchPolicy: 'no-cache',
        variables: { search: `order_id = ${orderId}` },
        onCompleted: (data) => {
            const newProducts = data.ordersProducts.edges.map(({ node: op }) => ({
                ...op.product,
                slot: op.sortOrder,
                quantity: op.quantity,
                candidates: op.sortOrder && candidates.filter(pc => pc.slot === op.sortOrder)
            }));
            setProducts(newProducts);
        }
    })

    const [getCustomerProducts] = useLazyQuery(GET_CUSTOMER_PRODUCTS, {
        onCompleted: (data) => {
            setCustomerProducts(data.customerProducts.edges.map(product => {
                return {
                    value: product.node.product.id,
                    label: `${product.node.product.name1} - ${product.node.product.name2}`
                };
            }));
        }
    });

    const [getCustomerSites] = useLazyQuery(GET_CUSTOMER_SITES, {
        onCompleted: (data) => {
            setCustomerSites(data.customersSites.edges.map (edge => {
                return { id: edge.node.site.id, siteCd: edge.node.site.siteCd, siteName: edge.node.site.siteName };
            }));
        }
    });

    const screenWidth = useWindowWidth();

    const [addProductToOrder] = useMutation(ADD_PRODUCT_TO_ORDER, {
        onCompleted: () => getOrdersProducts({ variables: { orderId: parseInt(orderId) } }),
        onError: error => console.log(error)
    });

    const [removeProductFromOrder] = useMutation(REMOVE_PRODUCT_FROM_ORDER, {
        onCompleted: () => getOrdersProducts({ variables: { orderId: parseInt(orderId) } }),
        onError: error => console.log(error)
    });

    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onError: error => console.log(error),
        onCompleted: () => {
            refetch()
        }
    });

    const [updateOrdersProduct] = useMutation(UPDATE_ORDERS_PRODUCT, {
        onError: error => console.log(error)
    });

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            setProductOptions([]);
            getProducts({ variables: { search: value } });
        };

        return debounce(loadOptions, 400);
    }, [getProducts]);

    useEffect(() => form.resetFields(), [products, customer, site, form]);
    useEffect(() => {
        if (data && data.order) {
            const order = data.order;
            order.orderType?.customers.length === 1
                ? setCustomer(order.orderType.customers[0])
                : setCustomer(order.customer || {});
            setCustomerList(order.orderType?.customers || []);
            
            setNumSlots(order.numSlots);
            setCandidates(order.productCandidates);
        
            if (order.ordersProducts) {
                const newProducts = data.order.ordersProducts.map(op => ({
                    ...op.product,
                    slot: op.sortOrder,
                    quantity: op.quantity,
                    candidates: op.sortOrder && order.productCandidates.filter(pc => pc.slot === op.sortOrder)
                }));
                setProducts(newProducts);
            }

            setMemoText(order.memo || '');

            if (order.boundingBoxes) {
                setBoundingBoxes(order.boundingBoxes?.filter(b => b.comment !== '納品先'));
                setIsOrderBoundingBoxes(true);
            } else {
                setBoundingBoxes(order.orderType.boundingBoxes);
            }

            if (order.site) {
                setSite(order.site);
            }
        }
    }, [data, loading, error]);

    useEffect(() => {
        customer?.id && getCustomerProducts({ variables: { search: `customer_id = ${customer.id}` } });
    }, [customer, getCustomerProducts]);

    useEffect(() => {
        customer?.id && getCustomerSites({ variables: { search: `customer_id = ${customer.id}` } });
    }, [customer, getCustomerSites]);

    useEffect(() => {
        getProducts({ variables: { search: `` } });
    }, [getProducts]);


    const [productRecommendations, setProductRecommendations] = useState([]);

    const {loading: recsLoading} = useQuery(GET_PRODUCT_RECOMMENDATIONS, {
        variables: { customerId: customer?.id },
        onCompleted: (data) => {
            console.log(data)
            const mapped = data.customerProductRecommendations.map((product) => {
                return { ...product.product, key: product.id, createdAt: product.createdAt, confidence: product.confidence }
            })
            setProductRecommendations(mapped);
        }
    });

    const recommendation_columns = [
        {
            title: <Typography.Text strong>品番</Typography.Text>,
            dataIndex: 'code',
            width: 150
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'name1',
            ellipsis: true,
            render: (name1, record) => <Typography.Text>{name1} {record.name2}</Typography.Text>,
            width: 300
        },
        {
            title: <Typography.Text strong>信頼度</Typography.Text>,
            dataIndex: 'confidence',
            render: (confidence) => <Typography.Text>{Math.round(confidence * 100)}%</Typography.Text>
        }
    ]

    if (loading) { return <Spin/>; }

    const clusterName = (data?.order?.orderType && data?.order?.orderType?.name) || 'N/A';

    if (!messagePresent && location && location.state && location.state.updated) {
        message.success(location.state.updated);
        setMessagePresent(true);
    }

    const handleMemoTextChange = (e) => {
        const { value } = e.target;
        setMemoText(value);
    };

    const saveMemo = () => {
        updateOrder({ variables: { input: { id: orderId, memo: memoText || null } } })
        .then(() => {
            message.success('備考を保存しました。', 3);
            setEditingMemo(false);
        });
        setEditingMemo(false);
    };

    const handleSearch = (value) => {
        setSearching(value.length > 0);
        if (value.length > 0) {
            debounceFetcher(value);
        }
    };

    const csvData = products.length > 0 ? products?.map(op => ({
        '日付': moment(op.createdAt).format('YYYY/MM/DD'),
        'ファイル名': data.order.s3ObjectKey,
        '得意先コード': customer?.code,
        '得意先名1': customer?.nameOne,
        '得意先名2': customer?.nameTwo,
        '商品コード': op.code,
        '商品名1': op.name1,
        '商品名2': op.name2,
        '数量': op.quantity,
        '現場コード': data.order.site?.siteCd,
        '現場名': data.order.site?.siteName,
        '備考': data.order.memo
    })) : [{
        '日付': moment(data.order.createdAt).format('YYYY/MM/DD'),
        'ファイル名': data.order.s3ObjectKey,
        '得意先コード': customer?.code,
        '得意先名1': customer?.nameOne,
        '得意先名2': customer?.nameTwo,
        '現場コード': data.order.site?.siteCd,
        '現場名': data.order.site?.siteName,
        '備考': data.order.memo
    }]

    if (!error) { return (
        <>
            {orderUpdated && (
                <>
                    <Col span={24}>
                        <Alert message="間違いデータに追加されました。" type="success" showIcon closable />
                    </Col>
                    <Divider />
                    <Redirect to={`${orderUpdated}`} />
                </>
            )}
            {data?.order?.isQuote && (
                <Col span={24}>
                    <Alert message="このPDFは見積もりです。" type="warning" closable style={{ marginBottom: '24px' }} />
                </Col>
            )}
            <Row justify="space-between">
                <Typography.Title level={5} style={{ alignSelf: 'center', marginBottom: 0 }}>
                    データ名: {data.order.name}　｜　フォーマットID: {clusterName}
                </Typography.Title>
                <Col>
                    <Space>
                        <UpdateOrderDropdown order={data.order} id={parseInt(orderId)} />
                        <UpdateOrderButton
                            id={parseInt(orderId)}
                            text={'保存してあとで修正'}
                            attribute={'saveForLater'}
                            value={true}
                            handleCompleted={() => {
                                setOrderUpdated(PATHS.orders);
                                onAction();
                                message.success('修正·ラベル付完了タブに保存しました');
                            }}
                        />
                        <UpdateOrderButton
                            id={parseInt(orderId)}
                            text={'完了'}
                            attribute={'done'}
                            value={true}
                            handleCompleted={() => {
                                csvLink.current.link.click()
                                setOrderUpdated(PATHS.orders);
                                onAction();
                            }}
                        />
                        <CSVLink
                            data={csvData}
                            filename={`${data.order.name}.csv`}
                            className="hidden"
                            ref={csvLink}
                            target="_blank"
                        />
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={8}>
                    <Form
                        form={form}
                        labelCol={{ span: 22 }}
                        wrapperCol={{ span: 22 }}
                        labelAlign="left"
                        layout="vertical"
                        colon={false}
                        name="saveOrder"
                    >
                        <Tabs defaultActiveKey="customer">
                            <Tabs.TabPane tab="得意先" key="customer">
                                <Form.Item name="customer" label="得意先">
                                    <Select
                                        showSearch
                                        placeholder={'得意先名を入力して選択してください。'}
                                        onSelect={customerId => {
                                            const orderTypeCustomer = data.order.orderType?.customers.find(customer => customer.id === customerId)
                                            updateOrder({ variables: { input: { id: orderId, customerId } } });
                                            if (orderTypeCustomer) {
                                                setCustomer(orderTypeCustomer)
                                                updateOrder({ variables: { input: { id: orderId, customerId: parseInt(customerId) } } })
                                            } else {
                                                getCustomer({ variables: { id: parseInt(customerId) } })
                                                data.order.orderType && updateExistingFormat({ variables: { input: { id: data.order.orderType?.id, customerId: parseInt(customerId) } } })
                                            }
                                        }}
                                        filterOption={(input, option) =>{
                                            if(option.children){
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                            }else if(option.label){
                                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                            }
                                        }}
                                    >
                                        <Select.OptGroup label='書式に紐付く得意先'>
                                        { customerList.length > 0 &&
                                            customerList.map(
                                                (customer) =>
                                                    <Select.Option key={customer.code} value={customer.id}>
                                                        { `${customer.nameOne} ${customer.nameTwo || ''}` }
                                                    </Select.Option>
                                            )
                                        }
                                        </Select.OptGroup>
                                        <Select.OptGroup label='マスターデータ'>
                                            { allCustomers && allCustomers.map(({ node: customer}) => {
                                                return <Select.Option key={customer.code + '-allCustomers'} value={customer.id}>
                                                    { `${customer.nameOne} ${customer.nameTwo || ''}` }
                                                </Select.Option>
                                            })}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                                {customer.code && (
                                    <>
                                        <Form.Item name="customer" label="得意先名" initialValue={`${customer.nameOne} ${customer.nameTwo || ''}`}>
                                            <Input suffix={<CopyButton text={`${customer.nameOne} ${customer.nameTwo || ''}`}/>}/>
                                        </Form.Item>
                                        <Form.Item name="customerCode" label="得意先コード" initialValue={customer.code}>
                                            <Input suffix={<CopyButton text={customer.code}/>}/>
                                        </Form.Item>
                                        <Form.Item name="kananame" label="カナ名" initialValue={customer.kanaName}>
                                            <Input suffix={<CopyButton text={customer.kanaName}/>}/>
                                        </Form.Item>
                                        <Form.Item name="zipcode" label="郵便番号" initialValue={customer.zipcode}>
                                            <Input suffix={<CopyButton text={customer.zipcode}/>}/>
                                        </Form.Item>
                                        <Form.Item name="address" label="住所" initialValue={customer.address}>
                                            <Input suffix={<CopyButton text={customer.address}/>}/>
                                        </Form.Item>
                                        <Row>
                                            <Col span="12">
                                                <Form.Item name="phone" label="TEL" initialValue={customer.phone}>
                                                    <Input suffix={<CopyButton text={customer.phone}/>}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span="11">
                                                <Form.Item name="fax" label="FAX" initialValue={customer.fax}>
                                                    <Input suffix={<CopyButton text={customer.fax}/>}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="商品内容" key="product">
                                { numSlots > 0 &&
                        <div>
                            {
                                [...Array(numSlots).keys()].map(i => {
                                    const product = products.find(p => p.slot === i + 1)
                                    return (
                                    <div
                                        key={i}
                                        style={{ display: 'flex', width: '100%', flexDirection: 'row' }}
                                        className="ant-row ant-form-item"
                                    >
                                        <Button
                                            style={{ backgroundColor: '#fafafa', width: '37px', borderRight: '0' }}
                                            icon={<MinusCircleOutlined className="minus-icon"/>} onClick={() => {
                                                updateOrder({ variables: { input: { id: orderId, numSlots: numSlots - 1 } } });
                                                removeProductFromOrder({
                                                    variables: {
                                                        input: {
                                                            orderId: data.order.id,
                                                            productId: products.find(p => p.slot === i + 1)?.id
                                                        }
                                                    }
                                                });
                                                products.forEach(p => {
                                                    if (p.slot > i + 1) {
                                                        updateOrdersProduct({
                                                            variables: {
                                                                input: {
                                                                    orderId,
                                                                    productId: p.id,
                                                                    sortOrder: p.slot - 1
                                                                }
                                                            }
                                                        });
                                                    }
                                                })
                                                setNumSlots(numSlots - 1);
                                            }}/>
                                        <Select
                                            style={{ width: '70%' }}
                                            allowClear
                                            showSearch
                                            value={product?.id}
                                            placeholder="商品を選択するか商品名を入力してください"
                                            onSearch={handleSearch}
                                            filterOption={false}
                                            onChange={value => {
                                                if (value) {
                                                    addProductToOrder({
                                                        variables: {
                                                            input: {
                                                                orderId: data.order.id,
                                                                productId: value,
                                                                sortOrder: i + 1
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    removeProductFromOrder({
                                                        variables: {
                                                            input: {
                                                                orderId: data.order.id,
                                                                productId: product?.id
                                                            }
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            {!searching ?
                                                <>
                                                    <Select.OptGroup label="AI候補">
                                                        {data.order.productCandidates
                                                        .filter(p => p.slot === i + 1)
                                                        .map(pc =>
                                                            <Select.Option
                                                                key={'candidate' + pc.product.id}
                                                                value={pc.product.id}>
                                                                {pc.product.name1} - {pc.product.name2}
                                                            </Select.Option>)}
                                                    </Select.OptGroup>
                                                    <Select.OptGroup label="購入履歴">
                                                        {customerProducts.map(product =>
                                                            <Select.Option key={product.value} value={product.value}>
                                                                {product.label}
                                                            </Select.Option>)}
                                                    </Select.OptGroup>
                                                </>
                                                :
                                                productOptions.map(product =>
                                                    <Select.Option key={product.value} value={product.value}>
                                                        {product.label}
                                                    </Select.Option>)}
                                                    {product && <Select.Option key={product.id} value={product.id}> {product.name1} - {product.name2} </Select.Option>}
                                        </Select>
                                        <Button icon={<CopyButton text={product?.name1 + ' - ' + product?.name2}/>} />
                                        <Input
                                            style={{ width: '10%', borderLeft: '0' }}
                                            defaultValue={products.find(p => p.slot === i + 1)?.quantity}
                                            onChange={e => {
                                                updateOrdersProduct({
                                                    variables: {
                                                        input: {
                                                            orderId: data.order.id,
                                                            productId: products.find(p => p.slot === i + 1)?.id,
                                                            quantity: parseInt(e.target.value),
                                                            sortOrder: i + 1
                                                        }
                                                    }
                                                });
                                                setProducts(products.map(p => {
                                                    if (p.slot === i + 1) {
                                                        return { ...p, quantity: parseInt(e.target.value) };
                                                    }

                                                    return p;
                                                }));
                                            }}
                                        />
                                    </div>
                                )})}
                        </div>}
                                <div>
                                    {products &&
                                        products.filter(p => p.slot === null).map((product, index) => {
                                            return (
                                            
                                            <div
                                                key={index}
                                                style={{ display: 'flex', width: '100%', flexDirection: 'row' }}
                                                className="ant-row ant-form-item"
                                            >
                                                <Input
                                                    style={{ width: 'calc(70% + 37px)', height: '100%' }}
                                                    value={`${product.name1} - ${product.name2}`}
                                                    addonBefore={
                                                        <Tooltip title="取り消し">
                                                            <MinusCircleOutlined
                                                                onClick={() => {
                                                                    removeProductFromOrder({
                                                                        variables: {
                                                                            input: {
                                                                                orderId: data.order.id,
                                                                                productId: product.id
                                                                            }
                                                                        }
                                                                    });
                                                                    setProducts(current => {
                                                                        return current.filter(p => p.id !== product.id);
                                                                    });
                                                                }}
                                                                className="minus-icon"
                                                            />
                                                        </Tooltip>
                                                    }
                                                />
                                                <Button icon={<CopyButton text={product?.name1 + ' - ' + product?.name2}/>} />
                                                <Input
                                                    style={{ width: '10%' }}
                                                    value={products.find(p => p.id === product.id)?.quantity}
                                                    onChange={e => {
                                                        updateOrdersProduct({
                                                            variables: {
                                                                input: {
                                                                    orderId: data.order.id,
                                                                    productId: product.id,
                                                                    quantity: parseInt(e.target.value)
                                                                }
                                                            }
                                                        });
                                                        setProducts(products.map(p => {
                                                            if (p.id === product.id) {
                                                                return { ...p, quantity: parseInt(e.target.value) };
                                                            }

                                                            return p;
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        )})}
                                        </div>

                                {addingItem && (
                                    <Form.Item fieldKey={'product'} label="商品">
                                        <Select
                                            showSearch
                                            filterOption={false}
                                            key={'selectProduct'}
                                            placeholder={'商品名またはコードを入力してください。'}
                                            onSearch={debounceFetcher}
                                            notFoundContent={loadingProducts ? <Spin size="small" /> : null}
                                            options={productOptions}
                                            onSelect={productId => {
                                                const newProduct = dataProducts.products.edges.find(
                                                    product => product.node.id === productId
                                                ).node;
                                                newProduct.slot = null;
                                                addProductToOrder({
                                                    variables: {
                                                        input: {
                                                            orderId: data.order.id,
                                                            productId
                                                        }
                                                    }
                                                });
                                                setAddingItem(false);
                                                setProducts([...products, newProduct]);
                                            }}
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            if (data?.order?.productCandidates.find(pc => pc.slot > numSlots)) {
                                                setNumSlots(numSlots + 1);
                                                updateOrder({ variables: { input: { id: orderId, numSlots: numSlots + 1 } } });
                                            } else {
                                                setAddingItem(true);
                                            }
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                            商品追加
                                    </Button>
                                </Form.Item>
                                { customer?.id && <>
                                    {!recsLoading ? 
                                        <Table style={{marginTop: "10rem", marginRight: "1rem"}} title={() => <Typography>オススメ商品</Typography>} columns={recommendation_columns} dataSource={productRecommendations} /> : <Spin className="spin-center"/>}
                                    </>   
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="現場" key="site">
                                <Form.Item label="現場" name="site" initialValue={site?.id}>
                                    <Select
                                        showSearch
                                        placeholder={'現場名を入力して選択してください。'}
                                        onSelect={siteId => {
                                            updateOrder({ variables: { input: { id: orderId, siteId } } });
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {customerSites.length > 0 &&
                                            customerSites.map(site => (
                                                <Select.Option key={site.id} value={site.id}>
                                                    {`${site.siteCd} ${site.siteName}`}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="備考" key="memo">
                                <TextArea
                                    style={{
                                        width: '95%',
                                        pointerEvents: !editingMemo ? 'none' : 'auto'
                                    }}
                                    rows={6}
                                    value={memoText}
                                    onChange={handleMemoTextChange}
                                    placeholder="ここに入力してください。"
                                />
                                <div
                                    style={{ display: 'flex', justifyContent: 'flex-end', width: '95%', marginTop: 12 }}
                                >
                                    <Space>
                                        <Button
                                            type="primary"
                                            disabled={editingMemo}
                                            onClick={() => setEditingMemo(true)}
                                        >
                                            書き込む
                                        </Button>
                                        <Button type="primary" disabled={!editingMemo} onClick={saveMemo}>
                                            保存する
                                        </Button>
                                    </Space>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                </Col>
                <Col span={16} style={{ backgroundColor: GREY, marginTop: '-24px' }}>
                    {!loading && data.order.presignedDownloadUrl && (
                        <>
                            <OrderPdf
                                showHideButton
                                pageWidth={screenWidth * 0.5}
                                file={data.order.presignedDownloadUrl}
                                unmodifiable
                                boundingBoxEnabled={boundingBoxes && boundingBoxes.length > 0}
                                orderTypeDimensions={
                                    data.order.orderType && {
                                        width: data.order.orderType.width,
                                        height: data.order.orderType.height
                                    }
                                }
                                annotationData={boundingBoxes}
                                initialPageNum={data.order.pageNum}
                                isOrderBoundingBoxes={isOrderBoundingBoxes}
                                siblings={data.order?.fax?.orders}
                            />
                            {data.order.orderType && (
                                <>
                                    <br />
                                    <Typography.Text className="ant-row ant-row-center" strong>
                                        フォーマットID : {clusterName}
                                    </Typography.Text>
                                    <br />
                                    <OrderPdf
                                        pageWidth={screenWidth * 0.35}
                                        file={data.order.orderType.samplePresignedDownloadUrl}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
    }
    else {return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography.Text strong style={{ marginBottom: '.5rem' }}>
        データがありません。
        </Typography.Text>
        <Button onClick={() => history.push(PATHS.orders)}>全てのデータ</Button>
    </div>;}
};

OrderDetails.propTypes = {
    onAction: propTypes.func,
    location: propTypes.object
};

export default withRouter(OrderDetails);
