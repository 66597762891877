import { notification } from 'antd';

export const notificationMessage = (type, message, description, className, duration = 6) => {
    notification[type]({
        message,
        description,
        className,
        duration
    });
};

export default notificationMessage;
