import React, { useState } from 'react';
import { Button, List, Divider, message } from 'antd';
import PasswordChangeForm from './PasswordChangeForm';
import { authenticationService } from '../../Utilities/authenticationService';

const AccountInformation = () => {
    const [passwordChangeForm, setPasswordChangeForm] = useState(false);
    const userId = authenticationService.currentUserValue.id;
    const userName = authenticationService.currentUserValue.username;
    const userData = [
        {
            title: 'ユーザーID',
            description: userId
        },
        {
            title: '氏名',
            description: userName
        }
    ];

    const returnToAccountInfo = () => {
        setPasswordChangeForm(false);
    };

    return <>
        { passwordChangeForm ?
            <PasswordChangeForm
                handleCompleted={() => {
                    setPasswordChangeForm(false);
                    message.success('パスワードを変更しました', 3);
                }}
                returnToAccountInfo={returnToAccountInfo}
            /> :
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={userData}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<li>{item.title}</li>}
                                description={item.description}
                            />
                            { item.title === '氏名' && (
                                <Button onClick={() => setPasswordChangeForm(true)} type="primary">パスワード変更</Button>
                            )}
                        </List.Item>
                    )}
                />
                <Divider style={{ margin: '0 0' }}/>
            </>
        }
    </>;
};

export default AccountInformation;

