import React, { useState } from 'react';
import { Button, Spin, Space, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import propTypes from 'prop-types';
import { CREATE_NEW_FORMAT, UPDATE_EXISTING_FORMAT, CREATE_BOUNDING_BOX } from '../../mutations';
import { v4 as uuidv4 } from 'uuid';
import notificationMessage from '../notificationMessage/notificationMessage';
import { Redirect } from 'react-router-dom';
import { PATHS } from '../../constants';

const SubmitNewFormatButton = ({
    boundingBoxes, orderId, orderTypeId, customerId, aspectRatio,
    unknownFormat, newOrderTypeWidth, newOrderTypeHeight }) => {
    const [loading, setLoading] = useState(false);
    const [finishedAllMutations, setFinishedAllMutations] = useState(false);

    const [createBoundingBox] = useMutation(CREATE_BOUNDING_BOX, {
        onCompleted: () => {
            setLoading(false);
        },
        onError: (gqlError) => {
            setLoading(false);
            notificationMessage(gqlError);
        }
    });

    const updateBoundingBoxes = (orderTypeIdForFormat) => {
        boundingBoxes.map(boundingBox => {
            setLoading(true);
            const x2 = boundingBox.mark.x + boundingBox.mark.width;
            const y2 = boundingBox.mark.y + boundingBox.mark.height;
            const coordinates = `(${x2},${y2})(${boundingBox.mark.x},${boundingBox.mark.y})`;
            return createBoundingBox({ variables: { input: {
                coordinates,
                orderTypeId: orderTypeIdForFormat,
                aspectRatio,
                comment: boundingBox.comment
            } } });
        });

        setFinishedAllMutations(true);
    };

    const [createNewFormat] = useMutation(CREATE_NEW_FORMAT, {
        onCompleted: (data) => {
            setLoading(false);
            updateBoundingBoxes(data.createOrderType.orderType.id);
        },
        onError: (gqlError) => {
            setLoading(false);
            notificationMessage(gqlError);
        }
    });

    const [updateExistingFormat] = useMutation(UPDATE_EXISTING_FORMAT, {
        onCompleted: (data) => {
            setLoading(false);
            updateBoundingBoxes(data.updateOrderType.orderType.id);
        },
        onError: (gqlError) => {
            setLoading(false);
            notificationMessage(gqlError);
        }
    });

    return <Space>
        <Button
            disabled={boundingBoxes.length === 0 || boundingBoxes.map(bb => bb.comment).includes(undefined) }
            type="primary"
            onClick={() => {
                setLoading(true);

                if (unknownFormat) {
                    setLoading(true);
                    createNewFormat({
                        variables: {
                            input: {
                                name: uuidv4(),
                                unknown: true,
                                customerId,
                                orderId,
                                representativeSampleId: orderId,
                                width: newOrderTypeWidth,
                                height: newOrderTypeHeight
                            }
                        }
                    });
                } else {
                    updateExistingFormat({
                        variables: {
                            input: {
                                id: orderTypeId,
                                customerId,
                                representativeSampleId: orderId
                            }
                        }
                    });
                }
            }}
        >
            完了
        </Button>
        { loading && <Spin /> }
        { boundingBoxes.map(bb => bb.comment).includes(undefined) &&
            <Typography.Text type='danger'>タイプを選択してください</Typography.Text> }
        { finishedAllMutations && <Redirect to={`${PATHS.orderDetails.replace(':orderId', orderId)}`} /> }
    </Space>;
};

SubmitNewFormatButton.propTypes = {
    boundingBoxes: propTypes.array.isRequired,
    orderTypeId: propTypes.number.isRequired,
    aspectRatio: propTypes.number.isRequired,
    newOrderTypeWidth: propTypes.number.isRequired,
    newOrderTypeHeight: propTypes.number.isRequired,
    customerId: propTypes.number.isRequired,
    orderId: propTypes.number.isRequired,
    unknownFormat: propTypes.bool.isRequired
};

export default SubmitNewFormatButton;
