import { NOT_ORDER_FORMATS } from '../constants';

export const computeStatus = order => {
    if (Object.values(NOT_ORDER_FORMATS).includes(order.node.orderType && order.node.orderType.name)) {
        return 'out-of-scope';
    }

    if (order.node.done) {
        return 'done';
    } else if (order.node.saveForLater) {
        return 'incomplete';
    } else {
        return 'uploaded';
    }
};
