import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Tooltip, Modal, Descriptions, Typography, Button } from 'antd';
import algoliasearch from 'algoliasearch/lite';
import './detailsearch.less';
import {
    InstantSearch,
    connectSearchBox,
    Highlight,
    Snippet,
    Configure,
    connectInfiniteHits
} from 'react-instantsearch-dom';
import { PATHS } from '../../constants';
import { SearchOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

const CustomSearchBox = connectSearchBox(({ refine }) => {
    // declared outside of the main component to prevent resetting upon rerender
    return (
        <Input
            allowClear
            prefix={<SearchOutlined style={{ color: 'rgb(118, 118, 118)' }} />}
            className="detail-search-box"
            placeholder="データ名、日付、担当者、得意先名、納品先名、住所、商品内容を検索できます。"
            size="large"
            onChange={event => {
                const searchString = event.target.value;
                refine(searchString);
            }}
        />
    );
});

const conditionalSnippet = (hit, attribute) => {
    //shows full attribute text if no match for snippet
    if (hit._snippetResult?.[attribute]?.matchLevel === 'none') {
        return hit[attribute];
    }

    return <Snippet attribute={attribute} hit={hit} />;
};

const conditionalHighlight = (hit, attribute) => {
    if (hit._highlightResult?.[attribute]) {
        return <Highlight attribute={attribute} hit={hit} />;
    }

    return hit[attribute];
};

const DetailSearch = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({}); //this set to the pdf record to be viewed when a row is clicked
    const [buttonState] = useState({
        name: 'primary',
        timestamp: 'primary',
        'uploader.username': 'primary',
        customerContent: 'primary',
        deliveryDestination: 'primary',
        orderDetails: 'primary'
    });
    const [searchableAttributes, setSearchableAttributes] = useState([
        'name',
        'timestamp',
        'uploader.username',
        'customer_content',
        'delivery_destination',
        'order_details'
    ]);

    const changeSearchableAttributes = attribute => {
        if (searchableAttributes.includes(attribute)) {
            const filtered = searchableAttributes.filter(e => (e !== attribute ? e : null));
            setSearchableAttributes(filtered);
            buttonState[attribute] = 'secondary';
        } else {
            setSearchableAttributes([...searchableAttributes, attribute]);
            buttonState[attribute] = 'primary';
        }
    };

    const openModal = record => {
        setCurrentRecord(record);
        setModalVisible(true);
    };

    const closeModal = () => {
        setCurrentRecord({});
        setModalVisible(false);
    };

    const nameRender = (text, record) => {
        return (
            <a
                href="#0"
                onClick={e => {
                    openModal(record);
                    e.preventDefault();
                }}
            >
                {conditionalHighlight(record, 'name')}
            </a>
        );
    };

    const uploadDateRender = (text, record) => {
        if (record._highlightResult?.timestamp?.matchLevel === 'full') {
            return <em className="ais-Highlight-highlighted">{new Date(text).toLocaleString()}</em>;
        }

        return new Date(text).toLocaleString();
    };

    const uploaderRender = (text, record) => {
        if (record._highlightResult?.uploader?.username) {
            return <Highlight attribute={'uploader.username'} hit={record} />;
        }

        return record.uploader?.username;
    };

    const customerContentRender = (text, record) => {
        return (
            <Tooltip placement="topLeft" title={text}>
                {conditionalSnippet(record, 'customer_content')}
            </Tooltip>
        );
    };

    const deliveryDestinationRender = (text, record) => {
        return (
            <Tooltip placement="topLeft" title={text}>
                {conditionalSnippet(record, 'delivery_destination')}
            </Tooltip>
        );
    };

    const orderDetailsRender = (text, record) => {
        return (
            <Tooltip placement="topLeft" title={text}>
                {conditionalSnippet(record, 'order_details')}
            </Tooltip>
        );
    };

    const columns = [
        //ant table columns are defined here
        {
            title: <Typography.Text strong>データ名</Typography.Text>,
            dataIndex: 'name',
            render: nameRender
        },
        {
            title: <Typography.Text strong>アップロード日時</Typography.Text>,
            dataIndex: 'timestamp',
            render: uploadDateRender
        },
        {
            title: <Typography.Text strong>担当者</Typography.Text>,
            dataIndex: 'uploader.username',
            ellipsis: {
                showTitle: false
            },
            render: uploaderRender
        },
        {
            title: <Typography.Text strong>得意先</Typography.Text>,
            dataIndex: 'customer_content',
            ellipsis: {
                showTitle: false
            },
            render: customerContentRender
        },
        {
            title: <Typography.Text strong>納品先</Typography.Text>,
            dataIndex: 'delivery_destination',
            ellipsis: {
                showTitle: false
            },
            render: deliveryDestinationRender
        },
        {
            title: <Typography.Text strong>商品内容</Typography.Text>,
            dataIndex: 'order_details',
            ellipsis: {
                showTitle: false
            },
            render: orderDetailsRender
        }
    ];

    const InfiniteResults = ({ hits, hasMore, refineNext }) => {
        if (hits.length > 0) {
            return (
                <>
                    <Table
                        fixed={'top'}
                        scroll={{ y: 700 }}
                        pagination={false}
                        className="detail-search-table"
                        columns={columns}
                        dataSource={hits}
                    />
                    <Button disabled={hasMore ? false : true} style={{ marginTop: '1rem' }} onClick={refineNext}>
                        もっと見る
                    </Button>
                </>
            );
        }

        return <div />;
    };

    const CustomInfiniteResults = connectInfiniteHits(InfiniteResults);

    return (
        <div className="detail-search">
            <InstantSearch //main instantSearch component
                searchClient={searchClient}
                indexName={'orders_' + process.env.REACT_APP_ENVIRONMENT}
            >
                <CustomSearchBox />
                <div className="search-buttons">
                    <Button type={buttonState.name} onClick={() => changeSearchableAttributes('name')}>
                        データ名
                    </Button>
                    <Button type={buttonState.timestamp} onClick={() => changeSearchableAttributes('timestamp')}>
                        アップロード日時
                    </Button>
                    <Button
                        type={buttonState['uploader.username']}
                        onClick={() => changeSearchableAttributes('uploader.username')}
                    >
                        担当者
                    </Button>
                    <Button
                        type={buttonState.customerContent}
                        onClick={() => changeSearchableAttributes('customer_content')}
                    >
                        得意先
                    </Button>
                    <Button
                        type={buttonState.deliveryDestination}
                        onClick={() => changeSearchableAttributes('delivery_destination')}
                    >
                        納品先住所
                    </Button>
                    <Button type={buttonState.orderDetails} onClick={() => changeSearchableAttributes('order_details')}>
                        商品内容
                    </Button>
                </div>
                <CustomInfiniteResults />
                <Configure restrictSearchableAttributes={searchableAttributes} />

                <Modal
                    title={<Typography.Text strong>データ詳細</Typography.Text>}
                    visible={modalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    width={1200}
                    footer={
                        <Link to={PATHS.orderDetails.replace(':orderId', currentRecord.key)}>Go to order details</Link>
                    }
                >
                    <Descriptions column={2} labelStyle={{ width: '10vw' }} bordered>
                        <Descriptions.Item label="データ名">
                            {conditionalHighlight(currentRecord, 'name')}
                        </Descriptions.Item>
                        <Descriptions.Item label="アップロード日時">
                            {conditionalHighlight(currentRecord, 'timestamp')}
                        </Descriptions.Item>
                        <Descriptions.Item label="担当者">
                            {currentRecord._highlightResult?.uploader?.username ? (
                                <Highlight attribute={'uploader.username'} hit={currentRecord} />
                            ) : (
                                currentRecord.uploader?.username
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="得意先">
                            {conditionalHighlight(currentRecord, 'customer_content')}
                        </Descriptions.Item>
                        <Descriptions.Item label="納品先住所" span="2">
                            {conditionalHighlight(currentRecord, 'delivery_destination')}
                        </Descriptions.Item>
                        <Descriptions.Item label="商品内容" span="2">
                            {conditionalHighlight(currentRecord, 'order_details')}
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </InstantSearch>
        </div>
    );
};

DetailSearch.propTypes = {
    hits: propTypes.array,
    hasMore: propTypes.bool,
    refineNext: propTypes.func
};

export default DetailSearch;
