import React, { useState } from 'react';
import { Space, Button, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import propTypes from 'prop-types';
import { UPDATE_EXISTING_FORMAT } from '../../mutations';
import notificationMessage from '../notificationMessage/notificationMessage';
import { Redirect } from 'react-router-dom';
import { PATHS } from '../../constants';

const UpdateOrderTypeButton = ({ orderId, orderTypeId, customerId }) => {
    const [loading, setLoading] = useState(false);
    const [finishedAllMutations, setFinishedAllMutations] = useState(false);
    const [updateExistingFormat] = useMutation(UPDATE_EXISTING_FORMAT, {
        onCompleted: () => {
            setLoading(false);
            setFinishedAllMutations(true);
        },
        onError: (gqlError) => {
            setLoading(false);
            notificationMessage(gqlError);
        }
    });

    return <Space>
        <Button
            disabled={!customerId}
            type="primary"
            onClick={() => {
                setLoading(true);
                updateExistingFormat({ variables: { input: { id: orderTypeId, customerId } } });
            }}
        >
            完了
        </Button>
        { loading && <Spin /> }
        { finishedAllMutations &&
            <Redirect to={
                {
                    pathname: `${PATHS.orderDetails.replace(':orderId', orderId)}`,
                    state: { updated: 'フォーマットに新規顧客を追加しました。' }
                }
            }
            />
        }
    </Space>;
};

UpdateOrderTypeButton.propTypes = {
    orderTypeId: propTypes.string.isRequired,
    orderId: propTypes.string.isRequired,
    customerId: propTypes.string.isRequired
};

export default UpdateOrderTypeButton;
