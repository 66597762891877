import React from 'react';
import {
    Col,
    Card,
    Spin,
    Statistic,
    Row
} from 'antd';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { useQuery } from '@apollo/client';
import { GET_ORDERS_KPI } from '../../queries';
import { computeStatus } from '../../Utilities/orderStatus';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';

const preprocessForLineChart = (orders, status) => {
    const filteredOrders = groupBy(orders.filter(order => order.status === status), 'timestamp');
    return Object.keys(filteredOrders).map(timestamp => ({
        timestamp,
        ordersCount: filteredOrders[timestamp].length
    })).sort((a, b) => (new Date(a.timestamp) - new Date(b.timestamp)));
};

const Dashboard = () => {
    const { data, loading } = useQuery(GET_ORDERS_KPI);

    if (loading) {
        return <Spin/>;
    }

    const ordersWithStatus = data.orders.edges.map(
        order => ({
            timestamp: order.node.createdAt.split('T')[0],
            status: computeStatus(order),
            user: order.node.user && order.node.user.username
        })
    ).filter(order => order.user !== null);

    const newlyUploadedOrders = preprocessForLineChart(ordersWithStatus, 'アップロード済み');
    const doneOrders = preprocessForLineChart(ordersWithStatus, '完了');
    const wrongFormatOrders = preprocessForLineChart(ordersWithStatus, '対象外');
    const toLabelOrders = preprocessForLineChart(ordersWithStatus, 'ラベル付未完了');

    const groupedOrdersByUser = groupBy(ordersWithStatus, 'user');
    const ordersPerUser = flatten(Object.keys(groupedOrdersByUser).map(user => {
        return [
            {
                username: user,
                type: 'アップロード済み',
                value: groupedOrdersByUser[user].filter(order => order.status === 'アップロード済み').length
            },
            {
                username: user,
                type: '完了',
                value: groupedOrdersByUser[user].filter(order => order.status === '完了').length
            },
            {
                username: user,
                type: 'ラベル付未完了',
                value: groupedOrdersByUser[user].filter(order => order.status === 'ラベル付未完了').length
            }
        ];
    }));

    return <>
        <Row gutter={16}>
            <Col span={6}>
                <Card>
                    <Statistic title='アップロード数' value={newlyUploadedOrders.length}/>
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title='完了数' value={doneOrders.length}/>
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title='ラベル付け修正未完了数' value={toLabelOrders.length}/>
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title='対象外' value={wrongFormatOrders.length}/>
                </Card>
            </Col>
        </Row>
        <br/>
        <Row gutter={16}>
            <Col span={12}>
                <Card title="アップロードされたPDF総数">
                    <LineChart data={newlyUploadedOrders} />
                </Card>
            </Col>
            <Col span={12}>
                <Card title="完了PDF総数">
                    <LineChart data={doneOrders} />
                </Card>
            </Col>
        </Row>
        <br/>
        <Row gutter={16}>
            <Col span={12}>
                <Card title="ラベル付け修正完了PDF総数">
                    <LineChart data={toLabelOrders} />
                </Card>
            </Col>
            <Col span={12}>
                <Card title="対象外PDF総数">
                    <LineChart data={wrongFormatOrders} />
                </Card>
            </Col>
        </Row>
        <br/>
        <Row gutter={16}>
            <Col span={24}>
                <Card title="社員別進捗状況">
                    <BarChart data={ordersPerUser} />
                </Card>
            </Col>
        </Row>
    </>;
};

export default Dashboard;
