import React, { useState } from 'react';
import { UPDATE_ORDER } from '../../mutations';
import { GET_ORDER_TYPE } from '../../queries';
import propTypes from 'prop-types';
import { PATHS, NOT_ORDER_FORMATS } from '../../constants';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
    Button,
    Modal,
    Radio
} from 'antd';
import { Redirect } from 'react-router-dom';

const NotAnOrderModal = ({ order }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [selectedOrderTypeName, setSelectedOrderTypeName] = useState('');

    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onCompleted: () => {
            setIsModalVisible(false);
            setRedirect(true);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [findOrderType] = useLazyQuery(GET_ORDER_TYPE, {
        onCompleted: (data) => {
            updateOrder({ variables: { input: { id: order.id, orderTypeId: data.orderTypes.edges[0].node.id } } });
        }
    });

    if (redirect) {
        return <Redirect to={PATHS.orders} />;
    }

    const onChange = (e) => {
        setSelectedOrderTypeName(e.target.value);
    };

    const handleOk = () => {
        findOrderType({ variables: { search: `name = ${NOT_ORDER_FORMATS[selectedOrderTypeName]}` } });
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
    };

    return <>
        <Button onClick={() => setIsModalVisible(true)}>ラベル付不可能</Button>
        <Modal
            title="ラベル付不可能のPDFの種類を選択してください。"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <Button key="back" onClick={ () => setIsModalVisible(false) }>
                    キャンセル
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    完了
                </Button>
            ]}
        >
            <Radio.Group onChange={onChange}>
                <Radio style={radioStyle} value={'地図'}>
                    地図
                </Radio>
                <Radio style={radioStyle} value={'全て手書きの注文書'}>
                    全て手書きの注文書
                </Radio>
                <Radio style={radioStyle} value={'部品カタログ'}>
                    部品カタログ
                </Radio>
                <Radio style={radioStyle} value={'見積書'}>
                    見積書
                </Radio>
            </Radio.Group>
        </Modal>
    </>;
};

NotAnOrderModal.propTypes = {
    order: propTypes.object.isRequired
};

export default NotAnOrderModal;
