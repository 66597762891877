export const KINTARO_API_ROOT = process.env.REACT_APP_KINTARO_API_ROOT;

export const ACTIVE_TAB_MAPPING = {
    uploaded: 'アップロード済み',
    incomplete: 'ラベル付・修正未完了',
    done: '完了',
    'out-of-scope': '対象外'
};

export const PATHS = {
    upload: '/upload',
    login: '/login',
    orders: '/orders',
    orderDetails: '/orders/:orderId',
    userSettings: '/userSettings',
    newFormat: '/newFormat/:orderId',
    import: '/import',
    products: '/products',
    clients: '/clients',
    dashboard: '/dashboard',
    addCustomer: '/addCustomer',
    addBoundingBox: '/addBoundingBox',
    fixBoundingBox: '/fixBoundingBox',
    formatMatch: '/formatMatch',
    detailSearch: '/detailsearch',
    productSearch: '/productsearch'
};

export const NOT_ORDER_FORMATS = {
    地図: '29',
    全て手書きの注文書: '34',
    部品カタログ: '415',
    見積書: '543'
};

export const GREY = '#F5F5F5';
