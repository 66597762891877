import React, { useEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import {
    Tabs,
    Skeleton,
    Col,
    Row,
    Input,
    Progress,
    Table,
    Tag,
    Typography,
    Button,
    Alert,
    List,
    Tooltip,
    Modal
} from 'antd';
import {
    CheckCircleOutlined,
    QuestionCircleOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import { OrderDetails } from '../';
import { GET_FAXES } from '../../queries';
import { getPastDate } from '../../Utilities/getPastDate';
import { authenticationService } from '../../Utilities/authenticationService';
import { useQuery } from '@apollo/client';
import { computeStatus } from '../../Utilities/orderStatus';
import DeleteOrdersButton from  './DeleteOrdersButton';
import history from '../../Utilities/history';
import { toFullWidthKatakana, toHalfWidthKatakana } from '../../Utilities/katakanaWidthConversion';
import { PATHS, ACTIVE_TAB_MAPPING } from '../../constants';
import './ordersTable.less';
import { useStickyState } from '../../Utilities/useStickyState';

const OrdersTable = ({ location }) => {
    const { path } = useRouteMatch();
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showUserUploads, setShowUserUploads] = useState(parseInt(localStorage.getItem('showCurrentUserUploads')) || false);
    const [activeKey, setActiveKey] = useStickyState('uploaded', 'kintaroOrdersTableActiveTab');
    const [pastDate] = useState(getPastDate(365));
    const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
    const [memoModalText, setMemoModalText] = useState('');

    const {
        data,
        error,
        loading,
        refetch,
        startPolling,
        stopPolling
    } = useQuery(GET_FAXES, {
        fetchPolicy: 'no-cache',
        variables: { search: `created_at >= ${pastDate} || save_for_later == true` }
    });

    const rowFilter = useCallback(row => {
        if (!showUserUploads) {
            return row.status === activeKey;
        } else {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            return row.uploader ? row.status === activeKey && row.uploader.username === currentUser.username : false;
        }
    }, [activeKey, showUserUploads]);

    const handleSearchChange = e => {
        const { value } = e.target;

        setSearchText(value);

        if (value !== '') {
            setRows(originalRows.filter(rowFilter).filter(row => {
                return row.name.toLowerCase().includes(value.toLowerCase())
                || toFullWidthKatakana(row.name.toLowerCase()).includes(value.toLowerCase())
                || toHalfWidthKatakana(row.name.toLowerCase()).includes(value.toLowerCase());
            }));
        } else {
            setRows(originalRows.filter(rowFilter));
        }
    };

    const showMemoModal = () => {
        setIsMemoModalVisible(true);
    };

    const hideMemoModal = () => {
        setIsMemoModalVisible(false);
    };

    const linkify = rowContent => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={PATHS.orderDetails.replace(':orderId', rowContent.firstPageId)}>
                {rowContent.name}
            </Link>
            { rowContent.memo &&
                <Tooltip title='メモ'>
                    <FileTextOutlined onClick={() => { setMemoModalText(rowContent.memo); showMemoModal(); }} />
                </Tooltip>
            }
        </div>
    );

    const taggify = text => {
        let color;
        let displayText = ACTIVE_TAB_MAPPING[text];

        switch (text) {
            case 'uploaded':
                color = 'blue';
                break;
            case 'incomplete':
                color = 'orange';
                break;
            case 'out-of-scope':
                color = 'red';
                break;
            case 'done':
                color = 'green';
                break;
            default:
                color = 'blue';
        }

        return <Tag color= {color}>{displayText}</Tag>;
    };

    const sorterPreferences = JSON.parse(localStorage.getItem('ordersTableSort') || '{}');
    const columns = [
        {
            title: <Typography.Text strong>データ名</Typography.Text>,
            dataIndex: 'name',
            render: (text, rowContent) => linkify(rowContent),
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: sorterPreferences.name || undefined,
            multiple: 3
        },

        {
            title: <Typography.Text strong>アップロード日時</Typography.Text>,
            dataIndex: 'timestamp',
            defaultSortOrder: sorterPreferences.timestamp || 'ascend',
            render: timestamp => new Date(timestamp).toLocaleString(),
            sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
            multiple: 2,
            width: 170
        },
        {
            title: <Typography.Text strong>作業日時</Typography.Text>,
            dataIndex: 'updatedAt',
            defaultSortOrder: sorterPreferences.updatedAt || 'ascend',
            render: updatedAt => new Date(updatedAt).toLocaleString(),
            sorter: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            multiple: 2,
            width: 170
        },
        {
            title: <Typography.Text strong>担当者</Typography.Text>,
            dataIndex: 'uploader',
            render: uploader => {
                return uploader ? uploader.username : 'admin';
            },
            sorter: (a, b) => {
                if (!a.uploader) {
                    a.uploader = { username: 'admin' };
                }

                if (!b.uploader) {
                    b.uploader = { username: 'admin' };
                }

                return a.uploader.username.localeCompare(b.uploader.username);
            },
            multiple: 4,
            defaultSortOrder: sorterPreferences.uploader || undefined,
            width: 200
        },
        {
            title: <Typography.Text strong>AI判定</Typography.Text>,
            dataIndex: 'unclassifiable',
            render: unclassifiable => {
                if (unclassifiable) {
                    return <QuestionCircleOutlined />;
                } else {
                    return <CheckCircleOutlined />;
                }
            },
            defaultSortOrder: sorterPreferences.unclassifiable || undefined,
            width: 80
        },
        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'status',
            render: (text, rowContent) => {
                if (rowContent.progress && (rowContent.progress !== 'finished' && rowContent.progress !== 'firstPageFinished')) {
                    let percent;
                    switch (rowContent.progress) {
                        case 'uploaded':
                            percent = 20;
                            break;
                        case 'jpeg_finished':
                            percent = 50;
                            break;
                        case 'prediction_finished':
                            percent = 75;
                            break;
                        default:
                            percent = 0;
                            break;
                    }

                    return <Progress percent={percent} size='small' />;
                } else {
                    return taggify(rowContent.status);
                }
            },
            defaultSortOrder: sorterPreferences.status || 'descend',
            sorter: (a, b) => a.status.localeCompare(b.status),
            multiple: 1,
            width: 150
        }
    ];

    useEffect(() => {
        if (data) {
            activeKey === 'uploaded' ? startPolling(2500) : stopPolling();

            const parsedRows = data.faxes.edges.map(
                fax => ({
                    key: fax.node.id,
                    firstPageId: fax.node.firstPageId,
                    progress: fax.node.progress,
                    name: fax.node.s3ObjectKey,
                    timestamp: fax.node.createdAt,
                    updatedAt: fax.node.updatedAt, //can change this one line to lastUpdated if we decide to reimplement that
                    unclassifiable: fax.node.unclassifiable,
                    uploader: fax.node.user,
                    status: computeStatus(fax),
                    memo: fax.node.memo
                })
            );
            const filteredRows = parsedRows.filter(rowFilter);

            setOriginalRows(parsedRows);
            setRows(filteredRows);
        }
    }, [data, activeKey, showUserUploads, rowFilter, startPolling, stopPolling]);

    if (loading) { return <Skeleton />; }

    if (error) {
        if (error.message !== 'Network error: Failed to fetch') {
            authenticationService.logout();
            history.push('/login');
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedOrderIds(selectedRows.map(row => parseInt(row.key)));
        }
    };

    const TableButtons = (
        <Row gutter={12} justify={'end'}>
            <Col span={5} style={{ marginRight: 'auto' }}>
                <Input.Search allowClear placeholder="検索" value={searchText} onChange={handleSearchChange} />
            </Col>
            <Col>
                <Button onClick={() => {
                    localStorage.setItem('showCurrentUserUploads', showUserUploads ? 0 : 1);
                    setShowUserUploads(!showUserUploads);
                }}>
                    {showUserUploads ? '全てのユーザー表示' : 'このユーザーのみ表示'}
                </Button>
            </Col>
            <Col>
                <Row justify="end" type="flex">
                    <DeleteOrdersButton
                        selectedOrderIds={selectedOrderIds}
                        handleCompleted={ () => { setSelectedOrderIds([]); refetch(); }} />
                </Row>
            </Col>
        </Row>
    );

    const TableHeader = (
        <div className='table-header' style={{ paddingTop: 0 }}>
            <Tabs defaultActiveKey={activeKey} onChange={(tab) => {
                setActiveKey(tab);
                setSearchText('');
            }}>
                {Object.keys(ACTIVE_TAB_MAPPING).map(k => (
                    <Tabs.TabPane tab={ACTIVE_TAB_MAPPING[k]} key={k} >{TableButtons}</Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );

    const failedFilesList = (
        <List
            dataSource={location?.state?.failedFiles}
            renderItem={filename => (
                <List.Item key={filename}>
                    {filename}
                </List.Item>
            )}
        />
    );

    const savedPaginationParams = localStorage.ordersTable && JSON.parse(localStorage.ordersTable);

    return <Switch>
        <Route exact path={path}>
            { location?.state?.failedFiles?.length > 0 &&
                <Alert
                    type='error'
                    closable
                    message='以下のファイルのアップロードに失敗しました'
                    description={failedFilesList}
                />
            }
            {TableHeader}
            <Table
                columns={columns}
                pagination={ {
                    defaultCurrent: (savedPaginationParams && savedPaginationParams.page) || 1,
                    defaultPageSize: (savedPaginationParams && savedPaginationParams.pageSize) || 10,
                    onChange: (page, pageSize) => localStorage.setItem('ordersTable', JSON.stringify({ page, pageSize }))
                } }
                dataSource={rows}
                onChange={(pagination, filters, sorter) => {
                    const sorterPreferences = JSON.parse(localStorage.getItem('ordersTableSort')) || {};
                    sorterPreferences[sorter.field] = sorter.order;
                    localStorage.setItem('ordersTableSort', JSON.stringify(sorterPreferences));
                }}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
            />
            { isMemoModalVisible &&
                <Modal title='メモ' visible={isMemoModalVisible} onCancel={hideMemoModal} footer={null}>
                    <Typography.Text>{memoModalText}</Typography.Text>
                </Modal>
            }
        </Route>
        <Route path={`${path}/:orderId`}>
            <OrderDetails onAction={() => { refetch(); }} />
        </Route>
    </Switch>;
};

OrdersTable.propTypes = {
    location: propTypes.object
};

export default OrdersTable;
