import React, { useEffect, useState } from 'react';
import { defaultShapeStyle, ReactPictureAnnotation } from 'react-picture-annotation';
import propTypes from 'prop-types';
import BoundingBoxTypeDropdown from './BoundingBoxTypeDropdown';

const latestBoundingBoxes = boundingBoxes => {
    if (boundingBoxes === undefined) {
        return [];
    }

    let latestDateComments = {
        得意先: false,
        注文内容: false,
        納品先: false
    };

    boundingBoxes.forEach(boundingBox => {
        if (
            !latestDateComments[boundingBox.comment] ||
            new Date(latestDateComments[boundingBox.comment].updatedAt) < new Date(boundingBox.updatedAt)
        ) {
            latestDateComments[boundingBox.comment] = boundingBox;
        }
    });

    return Object.values(latestDateComments).filter(Boolean);
};

const BoundingBox = ({
    annotationData,
    pdfFile,
    width,
    height,
    unmodifiable,
    originalPdfWidth,
    originalPdfHeight,
    isOrderBoundingBoxes,
    onChange: handleChange,
    orderTypeDimensions
}) => {
    const [annotations, setAnnotations] = useState([]);

    useEffect(() => {
        if (!annotationData) {
            return;
        }

        const isFlipped =
            originalPdfHeight === orderTypeDimensions.width && originalPdfWidth === orderTypeDimensions.height;
        const ratioVersusOrderType =
            isOrderBoundingBoxes || isFlipped
                ? {
                    width: 1,
                    height: 1
                }
                : {
                    width: originalPdfWidth / (orderTypeDimensions.width || originalPdfWidth),
                    height: originalPdfHeight / (orderTypeDimensions.height || originalPdfHeight)
                };
        setAnnotations(
            latestBoundingBoxes(annotationData).map(boundingBox => ({
                id: boundingBox.id,
                comment: boundingBox.comment,
                rectCoordinates: boundingBox.rectCoordinates,
                // to fix the tags, I flipped the box in both directions and
                // moved the location to the opposite corner of the original box
                mark: {
                    type: 'RECT',
                    x:
                        boundingBox.rectCoordinates.x * ratioVersusOrderType.width +
                        // not sure how the boxes got flipped to need this
                        boundingBox.rectCoordinates.width * ratioVersusOrderType.width,
                    y:
                        boundingBox.rectCoordinates.y * ratioVersusOrderType.height +
                        boundingBox.rectCoordinates.height * ratioVersusOrderType.height,
                    width: -boundingBox.rectCoordinates.width * ratioVersusOrderType.width,
                    height: -boundingBox.rectCoordinates.height * ratioVersusOrderType.height
                }
            }))
        );
    }, [annotationData, orderTypeDimensions, originalPdfHeight, originalPdfWidth, isOrderBoundingBoxes]);

    const onSelect = () => {
        console.log(annotations);
        handleChange(annotations);
    };

    const onChange = data => {
        setAnnotations(data);
    };

    const onDropdownSelection = data => {
        console.log(data);
    };

    return (
        <div style={{ width, height, position: 'relative', zIndex: 1 }}>
            <ReactPictureAnnotation
                image={pdfFile}
                onSelect={onSelect}
                annotationData={annotations}
                annotationStyle={{
                    ...defaultShapeStyle,
                    shapeStrokeStyle: '#eb6767',
                    fontBackground: '#1890ff',
                    fontColor: '#fff',
                    fontSize: 20
                }}
                scrollSpeed={0.0} // Disable zooming in and out
                inputElement={(value, onChange, onDelete) => (
                    <BoundingBoxTypeDropdown
                        value={value}
                        onChange={data => {
                            onChange(data);
                            onDropdownSelection(data);
                        }}
                        onDelete={onDelete}
                    />
                )}
                onChange={onChange}
                width={width}
                height={height}
            />
            {unmodifiable && (
                <div
                    style={{
                        opacity: 0,
                        zIndex: 2,
                        position: 'absolute',
                        display: 'block',
                        background: 'transparent',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0
                    }}
                ></div>
            )}
        </div>
    );
};

BoundingBox.propTypes = {
    annotationData: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string,
            comment: propTypes.string,
            mark: propTypes.shape({
                type: propTypes.string,
                height: propTypes.number,
                width: propTypes.number,
                x: propTypes.number,
                y: propTypes.number
            })
        })
    ),
    pdfFile: propTypes.string.isRequired,
    width: propTypes.number.isRequired,
    height: propTypes.number.isRequired,
    originalPdfWidth: propTypes.number.isRequired,
    originalPdfHeight: propTypes.number.isRequired,
    orderTypeDimensions: propTypes.object,
    unmodifiable: propTypes.bool,
    isOrderBoundingBoxes: propTypes.bool,
    onChange: propTypes.func
};

BoundingBox.defaultProps = {
    unmodifiable: false,
    onChange: () => {}
};
export default BoundingBox;
