import React, { useState } from 'react';
import './NewFormat.less';
import {
    Divider,
    Typography,
    Button,
    Row,
    Steps,
    Space,
    Spin,
    Col
} from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../../queries';
import { OrderPdf } from '../';
import CustomersSearch from './CustomersSearch';
import SubmitNewFormatButton from './SubmitNewFormatButton';
import NotAnOrderModal from './NotAnOrderModal';

const { Step } = Steps;

const NewFormat = () => {
    const { orderId } = useParams();
    const { data, loading } = useQuery(GET_ORDER, {
        variables: { orderId: parseInt(orderId) }
    });
    const [customerId, setCustomerId] = useState(null);
    const [boundingBoxes, setBoundingBoxes] = useState([]);
    const [unknownFormat, setUnknownFormat] = useState(undefined);
    const [currentStep, setCurrentStep] = useState(0);
    const [aspectRatio, setAspectRatio] = useState(0);

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const next = () => { setCurrentStep(currentStep + 1); };

    const prev = () => { setCurrentStep(currentStep - 1); };

    if (loading) { return <Spin/>; }

    const steps = [
        {
            title: 'アップロードしたPDFの得意先名を入力してください。',
            content: <Row>
                <Col>
                    <Space direction='vertical'>
                        <CustomersSearch onSelect={setCustomerId} />
                        <Button disabled={(currentStep === 0 && !customerId)}
                            type="primary" onClick={() => next()}>
                            次へ
                        </Button>
                    </Space>
                </Col>
                <Col>
                    { !loading && data.order.presignedDownloadUrl &&
                        <OrderPdf pageWidth={700} file={data.order.presignedDownloadUrl} />
                    }
                </Col>

            </Row>
        },
        {
            title: '以下のフォーマットとアップロードしたPDFが同じ場合は「はい（完了）」、そうでなければ「いいえ（次へ）」を選択してください。',
            content: <Col>
                <Row>
                    <Space>
                        <Button onClick={() => prev()}>
                            前へ
                        </Button>
                        <Button type={unknownFormat ? 'primary' : ''}
                            onClick={() => { setUnknownFormat(true); next(); }}>
                            いいえ（次へ）
                        </Button>
                        <Button type={!unknownFormat ? 'primary' : ''}
                            onClick={() => { setUnknownFormat(false); next(); }}>
                            はい（完了）
                        </Button>
                    </Space>
                </Row>
                <Divider />
                <Row>
                    <Col span={12} className='pdf-doc-header'>
                        フォーマットPDF
                        { !loading && data.order.secondBestOrderType &&
                            data.order.secondBestOrderType.samplePresignedDownloadUrl &&
                            <OrderPdf
                                pageWidth={700}
                                file={data.order.secondBestOrderType.samplePresignedDownloadUrl}
                            />
                        }
                    </Col>
                    <Col span={12} className='pdf-doc-header'>
                        アップロードしたPDF
                        { !loading && data.order.presignedDownloadUrl &&
                            <OrderPdf pageWidth={700} file={data.order.presignedDownloadUrl} />
                        }
                    </Col>
                </Row>
            </Col>
        },
        {
            title: '重要箇所を赤い枠で囲んでください。',
            content: <>
                <Col span={12}>
                    <Space direction='vertical'>
                        <Typography.Paragraph>
                            <ul>
                                <li>
                                    手順 1: 納品先、得意先、商品内容をそれぞれ赤枠で囲んでください。
                                </li>
                                <li>
                                    手順 2: 選択し終えたら赤枠外をクリックして青色に変更するのを確認してください。
                                </li>
                                <li>
                                    手順 3: 赤枠内をクリックして該当するタイプを選択してください。
                                </li>
                            </ul>
                        </Typography.Paragraph>
                        <Space>
                            <Button onClick={() => prev()}>
                                前
                            </Button>
                            <SubmitNewFormatButton boundingBoxes={boundingBoxes}
                                unknownFormat={unknownFormat}
                                aspectRatio={aspectRatio}
                                newOrderTypeWidth={width}
                                newOrderTypeHeight={height}
                                orderTypeId={data.order.orderType.id}
                                orderId={orderId} customerId={customerId} />
                        </Space>
                    </Space>
                </Col>
                <Col span={14} className='pdf-doc-header'>
                    アップロードしたPDF
                    { !loading && data.order.presignedDownloadUrl &&
                        <OrderPdf
                            file={data.order.presignedDownloadUrl}
                            unmodifiable={false}
                            onChange={setBoundingBoxes}
                            pageWidth={700}
                            setNewOrderTypeWidth={width => setWidth(width)}
                            setNewOrderTypeHeight={height => setHeight(height)}
                            getAspectRatio={(aspectRatio) => setAspectRatio(aspectRatio)}
                            boundingBoxEnabled
                            orderTypeDimensions={{ width: data.order.orderType.width, height: data.order.orderType.height }}
                            renderMode={'none'}
                            disablePanning={true}
                        />
                    }
                </Col>
            </>
        }
    ];

    return (
        <Space direction='vertical'>
            <Row>
                <Col span={6}>
                    <Typography.Title level={4}>新しいフォーマット</Typography.Title>
                </Col>
                <Col offset={14} span={2}>
                    { !loading && <NotAnOrderModal order={data.order} /> }
                </Col>
            </Row>
            <Row>
                <Steps direction='vertical' current={currentStep}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </Row>
            <Row>
                {steps[currentStep].content}
            </Row>

        </Space>
    );
};

export default NewFormat;
