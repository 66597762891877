import { useDropzone } from 'react-dropzone';

export const useS3Upload = ({ onUploadStart }) => {
    const onDrop = (acceptedFiles) => {
        onUploadStart(acceptedFiles);
    };

    return useDropzone({
        onDrop
    });
};

const inferContentType = (file) => {
    // if the file type is recognized, return it
    if (file.type?.length > 0) {
        return file.type;
    }

    // otherwise, infer the content type from the file extension
    const extension = file.name.split('.').pop();

    if (extension === 'xdw') {
        return 'application/vnd.fujixerox.docuworks';
    } else if (extension === 'pdf') {
        return 'application/pdf';
    } else if (extension === 'csv') {
        return 'text/csv';
    } else if (extension === 'xlsx') {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else {
        return 'application/octet-stream';
    }
};

export const uploadToS3 = async (file, presignedUploadUrl, onError, onUploadReady) => {
    const contentType = inferContentType(file);

    const response = await fetch(
        new Request(presignedUploadUrl, {
            method: 'PUT',
            body: file,
            headers: new Headers({
                'Content-Type': contentType
            })
        })
    );

    if (response.status !== 200) {
        onError && onError(response);
        return;
    }

    onUploadReady && onUploadReady();
};

