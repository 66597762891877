import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMERS } from '../../queries';
import { Spin, Select, Form, Input } from 'antd';
import propTypes from 'prop-types';
import debounce from 'lodash/debounce';

const CustomersSearch = ({ onSelect: handleSelect }) => {
    const { data, loading } = useQuery(GET_CUSTOMERS);
    const [form] = Form.useForm();
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showCustomerData, setShowCustomerData] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => {
        if (!loading) {
            setCustomers(
                data.customers.edges.filter(
                    customer => {
                        const names = [customer.node.kanaName, customer.node.nameOne, customer.node.nameTwo].filter(Boolean);
                        return names.find(name => name.toLowerCase().includes(searchTerm.toLowerCase()));
                    }
                )
            );
        }
    }, [loading, data, searchTerm]);

    useEffect(() => {
        form.resetFields(['customerName', 'customerCode', 'kananame', 'zipcode', 'address', 'phone', 'fax']);
    }, [selectedCustomer, form]);

    if (loading) {
        return <Spin/>;
    }

    const handleSearch = search => {
        setSearchTerm(search);
    };

    return (
        <Form
            form={form}
            labelCol={ { span: 22 } }
            wrapperCol={ { span: 22 } }
            labelAlign='left'
            layout='vertical'
            colon={false}
            style={{ marginTop: '24px' }}
        >
            <Form.Item
                name='customer'
                label='得意先'
                style={{ marginBottom: '12px' }}
            >
                <Select
                    showSearch
                    placeholder={'得意先名を入力して選択してください。'}
                    style={{ minWidth: '400px' }}
                    onSelect={(id) => {
                        handleSelect(id);
                        setSelectedCustomer(customers.find(customer => customer.node.id === id));
                        setShowCustomerData(true);
                    }}
                    onSearch={debounce(handleSearch, 100)}
                    filterOption={false}
                >
                    { customers.map(
                        customer =>
                            <Select.Option key={customer.node.code} value={customer.node.id}>
                                { `${customer.node.nameOne} ${customer.node.nameTwo || ''}` }
                            </Select.Option>
                    )
                    }
                </Select>
            </Form.Item>
            {showCustomerData && <>
                <Form.Item
                    name='customerName'
                    label='得意先名'
                    initialValue={ `${selectedCustomer.node.nameOne} ${selectedCustomer.node.nameTwo || ''}` }
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='customerCode'
                    label='得意先コード名'
                    initialValue={selectedCustomer.node.code}
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='kananame'
                    label='カナ名'
                    initialValue={selectedCustomer.node.kanaName}
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='zipcode'
                    label='郵便番号'
                    initialValue={selectedCustomer.node.zipcode}
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='address'
                    label='住所'
                    initialValue={selectedCustomer.node.address}
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='phone'
                    label='TEL'
                    initialValue={selectedCustomer.node.phone}
                    style={{ marginBottom: '12px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='fax'
                    label='FAX'
                    initialValue={selectedCustomer.node.fax}
                    style={{ marginBottom: '0px' }}
                >
                    <Input />
                </Form.Item>
            </>}
        </Form>
    );
};

CustomersSearch.propTypes = {
    onSelect: propTypes.func
};

export default CustomersSearch;
