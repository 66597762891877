import React from 'react';
import propTypes from 'prop-types';
import { Bar } from '@ant-design/charts';

const BarChart = ({ data }) => {
    const config = {
        data,
        isGroup: true,
        xField: 'value',
        yField: 'username',
        seriesField: 'type',
        height: 600,
        label: {
            position: 'right',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' }
            ]
        }
    };
    return <Bar {...config} />;
};

BarChart.propTypes = {
    data: propTypes.array
};

export default BarChart;
