import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddUserModal from './AddUserModal';
import NewUserModal from './NewUserModal';
import DeleteUsersButton from './DeleteUsersButton';
import PasswordResetButton from './PasswordResetButton';
import './userManagement.less';

const columns = [
    {
        title: <Typography.Text strong>ユーザーID</Typography.Text>,
        dataIndex: 'fullName'
    },
    {
        dataIndex: 'passwordReset'
    }
];

const UserManagement = () => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newUserCreated, setNewUserCreated] = useState(false);
    const [usersDeleted, setUsersDeleted] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { data, error, loading, refetch } = useQuery(GET_USERS);

    const returnToTable = () => {
        setCurrentModal('table');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchText(value);
        if (value !== '') {
            setRows(originalRows.filter(row => row.fullName.toLowerCase().includes(value.toLowerCase())));
        } else {
            setRows(originalRows);
        }
    };

    useEffect(() => {
        if (data) {
            const parsedRows = data.users.edges.map(
                user => ({
                    key: user.node.id,
                    fullName: user.node.username,
                    userId: user.node.id,
                    passwordReset: <PasswordResetButton userId={user.node.id} />
                })
            ).filter(user => user.fullName !== 'kintaro-inference');

            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [data]);

    if (loading) { return <Skeleton />; }

    if (error) {
        authenticationService.logout();
        history.push('/login');
    }

    const TableHeader = (
        <div className='table-header'>
            <Row justify='space-between'>
                <Col>
                    <Input.Search placeholder="検索" value={searchText} onChange={handleSearchChange} />
                </Col>
                <Col>
                    <Space>
                        <Button onClick={ () => setCurrentModal('addUser') } icon={<PlusOutlined/>}>新規追加</Button>
                        <DeleteUsersButton
                            selectedUserIds={selectedUserIds}
                            handleCompleted={ (usersInfo) => {
                                setCurrentModal('table');
                                setUsersDeleted(usersInfo);
                                setSearchText('');
                                refetch();
                            }} />
                    </Space>
                </Col>
            </Row>
            <br/>
            <Row>
                { newUserCreated &&
                    <>
                        <Col span={24}>
                            <Alert message="新規ユーザーを追加しました" type="success" showIcon closable/>
                        </Col>
                        <NewUserModal userInfo={newUserCreated} />
                    </>
                }
                { usersDeleted &&
                    <>
                        <Col span={24}>
                            <Alert
                                message={`${usersDeleted.map(user => user.username)}を削除しました。`}
                                type="success"
                                showIcon
                                closable
                            />
                        </Col>
                    </>
                }
            </Row>
        </div>
    );

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedUserIds(selectedRows.map(row => row.userId));
        }
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <React.Fragment>
                {TableHeader}
                <Table
                    columns={columns}
                    dataSource={rows}
                    rowSelection={{ type: 'checkbox', ...rowSelection }}
                />
            </React.Fragment>
        }
        { currentModal === 'addUser' &&
            <AddUserModal
                handleCompleted={ (userInfo) => { setCurrentModal('table'); setNewUserCreated(userInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default UserManagement;
