import gql from 'graphql-tag';

export const GET_PRESIGNED_UPLOAD_URL = gql`
query presignedUploadUrl($objectKey: String!) {
    presignedUploadUrl(objectKey: $objectKey) {
        objectKey
        expiresAt
        uploadUrl
    }
}`;

export const GET_USERS = gql`
query userList {
    users {
        totalCount
        edges {
            node {
                id
                username
                admin
                email
            }
        }
    }
}
`;

export const GET_CUSTOMERS = gql`
query customersList {
    customers {
        totalCount
        edges {
            node {
                id
                code
                kanaName
                nameOne
                nameTwo
                address
                zipcode
                phone
                fax
            }
        }
    }
}
`;

export const GET_CUSTOMER = gql`
query customer($id: Int!) {
    customer(id: $id) {
        id
        code
        kanaName
        nameOne
        nameTwo
        address
        zipcode
        phone
        fax
    }
}`

export const GET_PRODUCTS = gql`
query Products($search: String!) {
    products(search: $search, first: 10) {
        totalCount
        edges {
            node {
                id
                code
                name1
                name2
            }
        }
    }
}
`;

export const GET_PRODUCT_CODES = gql`
query prodctCodes {
    productCodes
}
`;

export const GET_ORDER_TYPE = gql`
query OrderType($search: String!){
    orderTypes(search: $search) {
        totalCount
        edges {
            node {
                id
                name
                height
                width
            }
        }
    }
}
`;

export const GET_ORDER = gql`
query Order($orderId: Int!){
    order(id: $orderId) {
        id
        wrong
        updatedAt
        s3ObjectKey
        name
        presignedDownloadUrl
        isQuote
        isMultipage
        pageNum
        memo
        site {
            id
            siteCd
            siteName
        }
        fax {
            id
            s3ObjectKey
            firstPageId
            numPages
            user {
                id
                username
            }
            saveForLater
            progress
            unclassifiable
            done
            createdAt
            updatedAt
            orders {
                id
                pageNum
            }
        }
        boundingBoxes {
            id
            comment
            rectCoordinates
            updatedAt
        }
        orderType {
            id
            name
            samplePresignedDownloadUrl
            unknown
            height
            width
            customers {
                id
                code
                nameOne
                nameTwo
                kanaName
                address
                phone
                fax
                zipcode
            }
            boundingBoxes {
                id
                comment
                rectCoordinates
                updatedAt
            }
        }
        secondBestOrderType {
            id
            name
            samplePresignedDownloadUrl
        }
        products {
            id
            code
            name1
            name2
        }
        ordersProducts {
            sortOrder
            product {
                id
                code
                name1
                name2
            }
            quantity
        }
        numSlots
        productCandidates {
            id
            slot
            probability
            product {
                id
                name1
                name2
                code
            }
        }
        customer {
            id
            code
            nameOne
            nameTwo
            kanaName
            address
            phone
            fax
            zipcode
        }
    }
}
`;

export const GET_ORDERS_PRODUCTS = gql`
query OrdersProducts($search: String!){
    ordersProducts(search: $search) {
        edges {
            node {
                sortOrder
                product {
                    id
                    code
                    name1
                    name2
                }
                quantity
            }
        }
    }
}`

export const GET_ORDERS = gql`
query ordersList {
    orders {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                name
                pageNum
                createdAt
                updatedAt
                wrong
                done
                progress
                unclassifiable
                orderType {
                    id
                    name
                }
                saveForLater
                user {
                    id
                    username
                }
            }
        }
    }
}
`;

export const GET_RECENT_ORDERS = gql`
query recentOrdersList($search: String!) {
    orders(search: $search) {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                name
                pageNum
                createdAt
                updatedAt
                wrong
                done
                progress
                unclassifiable
                memo
                orderType {
                    id
                    name
                }
                saveForLater
                user {
                    id
                    username
                }
            }
        }
    }
}
`;

export const GET_WRONG_ORDERS = gql`
query ordersList{
    orders(search: "wrong=true") {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                unclassifiable
                createdAt
                wrong
                done
                progress
            }
        }
    }
}
`;

export const GET_UNCLASSIFIABLE_ORDERS = gql`
query ordersList{
    orders(search: "unclassifiable=true") {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                createdAt
                unclassifiable
                wrong
                done
                progress
            }
        }
    }
}
`;

export const GET_ORDERS_KPI = gql`
query ordersList {
    orders {
        edges {
            node {
                id
                done
                saveForLater
                createdAt
                user {
                    id
                    username
                }
                orderType {
                    id
                    name
                }
            }
        }
    }
}
`;

export const GET_IMPORT_JOB = gql`
query ImportJob($importJobId: Int!){
    importJob(id: $importJobId) {
        id
        s3ObjectKey
        numRows
        numImported
        masterType
        createdAt
        updatedAt
        finishedAt
    }
}
`;

export const GET_PRODUCT_CANDIDATES = gql`
query ProductCandidates($search: String!) {
    productCandidates(search: $search) {
        edges {
            node {
                id
                slot
                probability
                product {
                    id
                    name1
                    name2
                    code
                }
            }
        }
    }
}
`;

export const GET_CUSTOMER_PRODUCTS = gql`
query CustomerProducts($search: String!) {
    customerProducts(search: $search) {
        edges {
            node {
                id
                product {
                    id
                    name1
                    name2
                    code
                }
                quantity
                mostRecentOrderDt
            }
        }
    }
}
`;

export const GET_PRODUCT_RECOMMENDATIONS = gql`
query customerProductRecommendations($customerId: ID!) {
    customerProductRecommendations(customerId: $customerId) {
        id
        productId
        customerId
        confidence
        productCode
        createdAt
        product {
            name1
            name2
            code
        }
    }
}
`;

export const GET_CUSTOMER_SITES = gql`
query CustomersSites($search: String!) {
    customersSites(search: $search) {
        edges {
            node {
                id
                site {
                    id
                    siteCd
                    siteName
                }
            }
        }
    }
}
`;

export const GET_FAXES = gql`
query faxesList($search: String!) {
    faxes(search: $search) {
        totalCount
        edges {
            node {
                id
                s3ObjectKey
                firstPageId
                numPages
                user {
                    id
                    username
                }
                saveForLater
                progress
                unclassifiable
                done
                createdAt
                updatedAt
                memo
            }
        }
    }
}
`;
