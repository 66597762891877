import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { OrderPdf } from '../';
import {
    Divider,
    Row,
    Col,
    Typography,
    Space,
    Button,
    message
} from 'antd';
import { Redirect, useHistory } from 'react-router-dom';
import { PATHS, GREY } from '../../constants';
import { useWindowWidth } from '../../Utilities/useWindowWidth';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER, CREATE_NEW_FORMAT } from '../../mutations';
import { v4 as uuidv4 } from 'uuid';

const FormatMatch = ({ location }) => {
    const { push } = useHistory();
    const order = location?.state?.order;
    const screenWidth = useWindowWidth();

    const [updateOrder] = useMutation(UPDATE_ORDER, {
        onCompleted: () => {
            message.success('新しいフォーマットIDが追加されました。');
            push(PATHS.orderDetails.replace(':orderId', order.id));
        },
        onError: error => {
            console.log(error);
        }
    });

    const [createNewFormat] = useMutation(CREATE_NEW_FORMAT, {
        onCompleted: data => {
            console.log('new format created: ', data);
            updateOrder({
                variables: {
                    input: {
                        id: order.id,
                        orderTypeId: data.createOrderType.orderType.id,
                        wrong: true
                    }
                }
            });
        },
        onError: error => {
            console.log(error);
        }
    });

    // If user selects yes, swap orderType and secondBestOrderType and mark order as wrong
    const handleYes = () => {
        updateOrder({
            variables: {
                input: {
                    id: order.id,
                    orderTypeId: order.secondBestOrderType.id,
                    secondBestOrderTypeId: order.orderType.id,
                    wrong: true
                }
            }
        });
    };

    // If user selects no:
    // - set order to be wrong
    // - set order type id to be uuid
    // - upload order to s3 with uuid as name
    const handleNo = () => {
        const newFormatId = uuidv4();
        createNewFormat({
            variables: {
                input: {
                    name: newFormatId,
                    unknown: true,
                    originalPdfName: order.name
                }
            }
        });
    };

    useEffect(() => {
        console.log(order);
    }, [order]);

    if (!location?.state?.order) {
        return <Redirect to={PATHS.orders} />;
    }

    return <>
        <Row>
            <Col span={6}>
                <Typography.Title level={4} strong>
                    フォーマットがFAXと合致しない
                </Typography.Title>
            </Col>
        </Row>
        <Divider/>
        <Row>
            <Col span={12}>
                <Row style={{ margin: '16px 0px' }}>
                    <Typography.Text>
                        PDFは以下のフォーマットと合致しますか。
                    </Typography.Text>
                </Row>
                <Row>
                    <Space>
                        <Button
                            type='primary'
                            onClick={handleNo}
                        >
                            いいえ
                        </Button>
                        <Button
                            type='primary'
                            onClick={handleYes}
                        >
                            はい（完了）
                        </Button>
                    </Space>
                </Row>
                <Row justify='center' style={{ marginTop: '24px' }}>
                    <Typography.Text strong>
                        {`フォーマットID: ${order.secondBestOrderType.name}`}
                    </Typography.Text>
                </Row>
                <Row justify='center'>
                    <OrderPdf
                        pageWidth={screenWidth * 0.4}
                        file={order.secondBestOrderType.samplePresignedDownloadUrl}
                    />
                </Row>
            </Col>
            <Col span={12} style={{ backgroundColor: GREY, marginTop: '-24px' }}>
                <Row justify='center' style={{ marginTop: '24px' }}>
                    <Typography.Text strong>
                        アップロードしたPDF
                    </Typography.Text>
                </Row>
                <Row justify='center'>
                    <OrderPdf
                        pageWidth={screenWidth * 0.4}
                        file={order.presignedDownloadUrl}
                    />
                </Row>
                <Row justify='center' style={{ marginTop: '24px' }}>
                    <Typography.Text strong>
                        {`フォーマットID: ${order.orderType.name}`}
                    </Typography.Text>
                </Row>
                <Row justify='center'>
                    <OrderPdf
                        pageWidth={screenWidth * 0.4}
                        file={order.orderType.samplePresignedDownloadUrl}
                    />
                </Row>
            </Col>
        </Row>
    </>;
};

FormatMatch.propTypes = {
    location: propTypes.object.isRequired
};

export default FormatMatch;
