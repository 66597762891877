import React from 'react';
import { Dropdown, Layout, Menu, Typography } from 'antd';
import propTypes from 'prop-types';
import {
    ImportOutlined,
    LineChartOutlined,
    DownOutlined,
    UploadOutlined,
    ContainerOutlined,
    SettingOutlined,
    LogoutOutlined,
    SearchOutlined
} from '@ant-design/icons';
import './authorizedlayout.less';
import { NavLink, useHistory } from 'react-router-dom';
import { PATHS } from '../../constants';
import { useStickyState } from '../../Utilities/useStickyState';

const { Header, Content, Sider } = Layout;

const AuthorizedLayout = ({ children, disableBorder, onLogout }) => {
    const history = useHistory();
    const [collapsed, setCollapsed] = useStickyState(false, 'kintaroAuthorizedLayoutCollapsed');

    const historyToViewText = historyPath => {
        switch (historyPath) {
            case PATHS.upload:
                return 'アップロード';
            case PATHS.orders:
                return '全てのデータ';
            case PATHS.import:
                return 'マスタデータインポート';
            case PATHS.detailSearch:
                return 'データ検索';
            case PATHS.productSearch:
                return '商品検索';
            case PATHS.dashboard:
                return '進捗状況ダッシュボード';
            case PATHS.userSettings:
                return '設定';
            default:
                console.log(history);
        }
    };

    const refreshPath = current => {
        // if current path matches the attempted pushed path, go to /null, then go back to refresh page
        if (current === history.location.pathname) {
            history.push('/null');
            history.goBack();
        }
    };

    const Sidebar = (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={value => setCollapsed(value)}
            width={200}
            style={{ position: 'fixed', zIndex: '11' }}
            className="site-layout-background site-layout-sidebar"
        >
            {!collapsed && (
                <img
                    style={{ margin: '20px 0px 18px 21px' }}
                    src={require('../../static/marukinlogo.gif')}
                    alt="marukinlogo"
                    className="app-logo"
                />
            ) }

            <Menu mode="inline" defaultOpenKeys={['data']} style={{ height: '100vh', borderRight: 0 }}>
                <Menu.Item icon={<UploadOutlined />} key="1" onClick={() => refreshPath(PATHS.upload)}>
                    <NavLink to={PATHS.upload}>アップロード</NavLink>
                </Menu.Item>
                <Menu.Item key="data" icon={<ContainerOutlined />} onClick={() => refreshPath(PATHS.orders)}>
                    <NavLink to={PATHS.orders}>全てのデータ</NavLink>
                </Menu.Item>
                <Menu.Item key="searchOrders" icon={<SearchOutlined />} onClick={() => refreshPath(PATHS.detailSearch)}>
                    <NavLink to={PATHS.detailSearch}>データ検索</NavLink>
                </Menu.Item>
                <Menu.Item
                    key="searchProducts"
                    icon={<SearchOutlined />}
                    onClick={() => refreshPath(PATHS.productSearch)}
                >
                    <NavLink to={PATHS.productSearch}>商品検索</NavLink>
                </Menu.Item>
                <Menu.Item key="import" icon={<ImportOutlined />} onClick={() => refreshPath(PATHS.import)}>
                    <NavLink to={PATHS.import}>マスタデータインポート</NavLink>
                </Menu.Item>
                {JSON.parse(localStorage.currentUser).admin && (
                    <Menu.Item
                        key="dashboard"
                        icon={<LineChartOutlined />}
                        onClick={() => refreshPath(PATHS.dashboard)}
                    >
                        <NavLink to={PATHS.dashboard}>進捗状況ダッシュボード</NavLink>
                    </Menu.Item>
                )}
            </Menu>
        </Sider>
    );

    const UserMenu = (
        <Menu key="user-panel" className="user-panel">
            <Menu.Item icon={<SettingOutlined />} key="settings">
                <NavLink to={PATHS.userSettings}>設定</NavLink>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />} key="logout" onClick={onLogout}>
                ログアウト
            </Menu.Item>
        </Menu>
    );

    const Topbar = (
        <Header
            className="site-layout-background main-header"
            style={{ width: collapsed ? 'calc(100%  - 80px)' : 'calc(100% - 200px)' }}
        >
            <Typography.Text strong style={{ float: 'left' }}>
                {historyToViewText(history.location.pathname)}
            </Typography.Text>
            <Dropdown overlay={UserMenu} trigger={['click']}>
                <div>
                    {JSON.parse(localStorage.currentUser).username} <DownOutlined />
                </div>
            </Dropdown>
        </Header>
    );

    return (
        <Layout>
            <Layout>
                {Sidebar}
                <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
                    {Topbar}
                    <Content
                        className={disableBorder ? '' : 'site-layout-background'}
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                            marginTop: 64
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

AuthorizedLayout.propTypes = {
    children: propTypes.element,
    onLogout: propTypes.func,
    disableBorder: propTypes.bool
};

export default AuthorizedLayout;
